import React, { useEffect } from 'react'
import './profile1.css'

import firebase from "firebase/app";
import "firebase/storage";

import axios from "axios";
import { REQUEST_URL } from "../../config";
import firebaseRef from "../../firebaseRef";
import { fireEvent } from '@testing-library/dom';

function Profile1(props) {

    const [name, setName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [bio, setBio] = React.useState('')
    const [city, setCity] = React.useState('')
    const [contact, setContact] = React.useState('')
    const [yearOfStudy, setYearOfStudy] = React.useState('')
    const [college, setCollege] = React.useState('')
    const [file, setFile] = React.useState('')
    const [uploading, setUploading] = React.useState(false)
    const [downloadURL, setDownloadURL] = React.useState('')
    const [resDone, setResDone] = React.useState(false)
    const [resumeRef2, setResumeRef2] = React.useState('')

    // console.log(firebaseRef.auth().currentUser.uid)

    useEffect(() => {
        axios
        .post(`${REQUEST_URL}/account/getProfile`, {
            userID: firebaseRef.auth().currentUser.uid,
            accountType: 1,
        })
        .then((response) => {
            const profile = response.data;
            //setProfile(profile)
            setName(profile.name)
            setEmail(profile.email)
            setBio(profile.bio)
            setCity(profile.city)
            setContact(profile.contact)
            setYearOfStudy(profile.yearOfStudy)
            setCollege(profile.college)
            setResumeRef2(profile.resumeRef)
            console.log(response.data);
        });
    }, [])

    function updateProfile(event) {
        handleUpload()
        //alert('yay')
    }

    function handleFileSelect(e) {
        // console.log('upload press')
        setFile(e.target.files[0])
        //this.setState({file: e.target.files[0]})
    }

    function handleUpload() {
        setUploading(true)
        var uploadTask = firebaseRef.storage().ref().child(`resume/${firebaseRef.auth().currentUser.uid}.pdf`).put(file)
        uploadTask.on('state_changed', function(snapshot){
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
              default:
                console.log('working normally')
                break
            }
          }, function(error) {
            // Handle unsuccessful uploads
          }, function(reference) {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                //console.log('File available at', downloadURL);
                setDownloadURL(downloadURL)

                axios.post(`${REQUEST_URL}/account/updateAccount`, {
                    id: firebaseRef.auth().currentUser.uid,
                    email: firebaseRef.auth().currentUser.email,
                    accountType: 1,
                    resumeRef: downloadURL,
                    city: city,
                    contact: contact
                }).then(() => {
                    //console.log('profile updated')
                    alert('profile updated')
                    setResDone(true)
                }).catch((err) => {
                    //console.log(err)
                })

            });
          });
    }

    return (
        <div style={{height: "100%"}}>
            <div className="newmaincontainer">
           <div className="newuppercontainer">
                <div className="newimage"></div>
                <div className="rightimage">
                    <div className="newname">{name ? name : ''}</div>
                    <div className="newdesignation">{bio ? bio : ''}</div>
                </div>    
           </div>
           <div className="lowercontainer">
                <div className="lowerbox">
                    
                        <div className="info-card">
                            <div className="namehead">Name</div>
                                <div className="boxy">
                                    <input disabled={true} value={name ? name : ''} type="text" className="boxy"/>
                                </div>
                        </div>
                        <div className="info-card">
                            <div className="namehead">Email</div>
                                <div className="boxy">
                                    <input disabled={true} value={email ? email : ''} type="email" className="boxy" />
                            </div>
                        </div>
                        <div className="info-card">
                            <div className="namehead">City</div>
                                <div className="boxy">
                                    <input onChange={e => setCity(e.target.value)} value={city} type="text" className="boxy" />
                                </div>
                        </div>

                    <div className="info-card">
                        <div className="namehead">Contact number</div>
                            <div className="boxy">
                                <input onChange={e => setContact(e.target.value)} value={contact} type="text" className="boxy" />
                            </div>
                    </div>
                    
                    <div className="info-card">
                        <div className="namehead">Year of study</div>
                        <div className="boxy">
                            <input disabled={true} value={yearOfStudy ? yearOfStudy : ''} type="text" className="boxy" />
                        </div>       
                    </div>
                    <div className="info-card">
                        <div className="namehead">College name</div>
                        <div className="boxy">
                            <input disabled={true} value={college ? college : ''} type="text" className="boxy" />
                        </div>
                    </div>

                    <div className="info-card">
                        <div className="namehead">Resumé</div>
                        <div className="boxy">
                            <input style={{display: 'inline'}} accept='.pdf' id='resume' type='file' onChange={handleFileSelect}/>
                        </div>
                    </div>

                    <div className="info-card">
                        <div className="namehead">Resumé</div>
                        <div className="boxy">
                            {
                                resDone ? <p>Resume uploaded successfully</p> : <p></p>
                            }
                            {
                                resumeRef2 && resumeRef2 !== 'null' ? <p>Resume uploaded already</p> : <p></p>
                            }
                        </div>
                    </div>

                    </div>

                    <button style={{height: "fit-content"}} onClick={e => file ? updateProfile(e) : alert('choose resume first')} >Update Profile</button>

                </div>
          
       </div>
        </div>
    )
}

export default Profile1
