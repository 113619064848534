import React from "react"
import { Link } from "react-router-dom"
import Footer from "../Footer/Footer"
import "./Multitude.css"

const Multitude = () => {
    return(
        <div>
            <div style={{ display: "flex" }}>
                <img src={require("../Assets/Big Launch.gif")} style={{ width: "100%" }} />
            </div>
            <div style={{display:"flex", marginTop:"20px", marginBottom:"20px"}}>
                <div className="multi_head" style={{borderRight:"1px solid rgba(0,0,0,0.3)"}}>
                    Coffee with Cel
                </div>
                <div className="multi_head">
                    Entre-Speaks
                </div>
            </div>
            <div className="register_head">
                COFFEE WITH CEL
            </div>
            <div style={{textAlign:"right", marginRight:"25px"}}>
                <Link to="/coffee with cel" className="navigate">
                    View all the session <i className="fa fa-arrow-right" style={{fontSize:"40px"}}></i>
                </Link>
            </div>
            <div className="register_head">
                ENTRESPEAKS
            </div>
            <div style={{textAlign:"right", marginRight:"25px"}}>
                <Link to="/entrespeaks" className="navigate">
                    View all the session <i className="fa fa-arrow-right" style={{fontSize:"40px"}}></i>
                </Link>
            </div>
            <Footer />
        </div>
    )
}

export default Multitude;