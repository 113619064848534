import React from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import Competitions from "./Launchpad/competitions/competitions"
import Contact from "./Contacts/Contacts";
import Econnect from "./Econnect/Econnect";
import Footer from "./Footer/Footer";
import Home from "./Home/Home";
import Drive from "./Internship Drive/Internship Drive";
import Events from "./Launchpad/Events/Events";
import Launchpad from "./Launchpad/Launchpad";
import Multitude from "./Multitude/Multitude";
import Menubar from "./Navigation/Menubar";
import Toolbar from "./Navigation/Toolbar";
import Gaming from "./Gaming/gaming"
import Peer from "./Peer to Peer/Peer";
import Coffee from "./Multitude/Coffee";
import Quiz from "./Launchpad/Quiz/quiz"
import Entrespeak from "./Multitude/EntreSpeak";
import BP from "./Launchpad/BP/BP";
import GR from "./Launchpad/GR/GR";
import expo from "./Launchpad/Start Up Expo/expo";
import Speakers from "./Launchpad/Sub Pages/Speakers";
import Sponsors from "./Launchpad/Sponsors/Sponsors";
import Community from "./Community/Community";
import Login from "./Internship Drive/Login";
import Dashboard from "./Internship Drive//Dashboard";
import AuthPayment from "./AuthPayment/AuthPayment";
import AuthUtil from "./Internship Drive/AuthUtil";
import Workshop from "./Launchpad/PM Workshop/Workshop"
import appliedcompany from './Internship Drive/Components/appliedcompany'
import profile1 from "./Internship Drive/Components/profile1";

function App() {
  return (
    <div>
      <Toolbar />
      <Switch>
        <Route path="/e-summit" component={Launchpad} />
        <Route path="/media" component={Footer} />
        <Route path="/contacts" component={Contact} />
        <Route path="/" exact component={Home} />
        <Route path="/valorant" component={Gaming} />
        <Route path="/programs" component={Econnect} />
        <Route path="/competitions" component={Competitions} />
        <Route path="/peer to peer" component={Peer} />
        <Route path="/multitude learning" component={Multitude} />
        <Route path="/coffee with cel" component={Coffee} />
        <Route path="/quiz" component={Quiz} />
        <Route path="/entrespeaks" component={Entrespeak} />
        <Route path="/beyond profits" component={BP} />
        <Route path="/ground reality" component={GR} />
        <Route path="/startup-expo" component={expo} />
        <Route path="/guest speakers" component={Speakers} />
        <Route path="/sponsors" component={Sponsors} />
        <Route path="/pm-workshop" component={Workshop} />
        <Route path="/community" component={Community} />
        {/* <PublicRoute restricted={false} component={Drive} path="/internship drive" exact /> */}
        {/* <PublicRoute restricted={true} component={Login} path="/internship drive/signin" exact /> */}
        {/* <PrivateRoute component={Dashboard} path="/internship drive/dashboard" exact /> */}
        <Route exact path="/internship-drive" component={Drive} />
        <Route exact path="/internship-drive/app" component={AuthUtil} />
        <Route exact path="/internship-drive/pr" component={profile1} />
        {/* <Route path="/internship drive/" component={Login} /> */}
        {/* <Route path="/internship drive/" component={Dashboard} /> */}
        <Route path="/authPayment" component={AuthPayment}></Route>
      </Switch>
    </div>
  );
}

export default App;
