import React from "react"
import Footer from "../Footer/Footer"
import "./gaming.css"
import Toolbar from "../Navigation/Toolbar"
const Gaming = () => {

    return (
        <div style={{ overflow: "scroll" }}>
            <Toolbar />
            <div className="gm_1">
                <div style={{ transform: "translate(0%, 30%)", width: "fit-content" }} class="wrapper">
                    <div className="spon">Powered By </div>
                    <div style={{ margin: "5px" }}><img style={{borderRadius:"50%" }}src={require("./spon.jpeg")} /></div>
                    <h1><span style={{ letterSpacing: "2px" }} class="text_1"> VALORANT GAMING <br />TOURNAMENT </span></h1>
                    <div className="page1_sub" style={{ fontSize: "25px", fontWeight: "600", color: "var(--color-3)", fontFamily:"Montserrat, sans-serif" }}>
                        A Test Of Competitive Spirit
                           <br />
                    </div>
                    <button style={{ backgroundColor: "var(--color-1)", width: "fit-content", marginTop: "5px" }} className="id_login">
                        <a style={{ textDecoration: "none", color: "var(--color-2)" }} href="https://www.meraevents.com/event/launchpad-2021-5th-edition?ucode=organizer" target="_blank">
                            BUY TICKETS</a></button>
                </div>
            </div>
            <div className="schedule">
                <div className="sched_head">
                    SCHEDULE
                </div>
            <div className="t" style={{display:"flex", flexWrap:"wrap", alignItems:"center"}}>
                 <div className="tables">
                     <table>
                         <tr>
                                <th>
                             ROUND 1
                             </th>
                             </tr>
                             <tr>
                                 <td> Ed_shot  VS   Hallar eSports</td>
                             </tr>
                             <tr>
                                 <td> Choke kings  VS  Cylum 3</td>
                             </tr>
                             <tr>
                                 <td> Valo Boys  VS  Hailing Flames</td>
                             </tr>
                             <tr>
                                 <td> AKD  VS  SNA</td>
                             </tr>
                             <tr>
                                 <td> MH15  VS  Team Kanyarasi</td>
                             </tr>
                     </table>
                 </div>
                 <div className="tables">
                     <table>
                         <tr>
                                <th>
                             QUARTER FINALS
                             </th>
                             </tr>
                             <tr>
                                 <td> team1  VS  TBD</td>
                             </tr>
                             <tr>
                                 <td> TBD  VS  TBD</td>
                             </tr>
                             <tr>
                                 <td> TBD  VS elite</td>
                             </tr>
                             <tr>
                                 <td> TBD  VS  Supreet Gamerbois</td>
                             </tr>
                     </table>
                 </div>
                 <div className="tables">
                     <table>
                         <tr>
                                <th>
                             SEMI FINALS
                             </th>
                             </tr>
                             <tr>
                                 <td> TBD  VS  TBD</td>
                             </tr>
                             <tr>
                                 <td> TBD  VS  TBD</td>
                             </tr>
                     </table>
                 </div>
                 <div className="tables">
                     <table>
                         <tr>
                                <th>
                             FINALS
                             </th>
                             </tr>
                             <tr>
                                 <td> TBD  VS  TBD</td>
                             </tr>
                     </table>
                 </div>
            </div>
        </div>
            <div className="fb_live">
                <div style={{ width: "80%", height: "90%" }}>
                    <video width="100%" height="100%" controls>
                        <source src={require("../Assets/ID.mp4")} type="video/mp4" />
                    </video>
                </div>
            </div>
            <div className="rules">
                <div className="rules_head">
                    RULES FOR PARTICIPATION
                 </div>
                <div className="rules_content" style={{ fontFamily: "Roboto" }}>
                    <span className="h">TOURNAMENT FORMAT</span>
                    <br />The tournament will be played in Single Elimination Bo1 format.

                    <br /><br /><span className="h"> MAP POOL</span>
                    <br />The tournament will be played on the current competitive map pool, which consists of the following maps:
                    <br />      •	Ascent
                    <br />      •	Bind
                    <br />      •	Split
                    <br />      •	Haven
                    <br />      •	Icebox

                    <br /><br /><span className="h">VETO</span>
                    <br />Bo1 (Team A is the better-seeded one):
                    <br />1.	Team A – banning the first map
                    <br />2.	Team B – banning the second map
                    <br />3.	Team A – banning the third map
                    <br />4.	Team B – banning the fourth map
                    <br />5.	Team A – choosing the side on the fifth map (remained one)


                    <br /><br />Tournament Round will start as scheduled unless said otherwise. Some rounds might get delayed. The schedule can be found on the Challonge community of Esports Club. The tournament will be for a span of 3 days, starting from 25th March till 27th March.
                                <br />The team winning the final will get a cash prize.
                                <br />Matches will go as per schedule unless informed by admins due to any technical delays.
                                <br />Only the finals and the semi finals will be live streamed on facebook .
                                <br /><br /><span className="h"> NOTE: </span> The team captain must register for the whole team and pay the entry fee, and mention all the required information asked while registering. He must fill a minimum of 5 players and they must be the same players attending the tournament. 6th player as a substitute can be filled which is not mandatory (it’s just optional).

                                <br /><br /><span className="h">RULE VIOLATION - PUNISHMENT</span>
                    <br />•  Using a player not on the roste - <span className="w">FORFEIT FROM THE MATCH</span>
                    <br />•  Playing with the wrong game account - <span className="w">FORFEIT FROM THE MATCH</span>
                    <br />•  Usage of a glitch/bug - <span className="w">CASE-BY-CASE</span>
                    <br />•  Stream Sniping- <span className="w">CASE-BY-CASE</span>
                    <br />•  Inappropriate/disruptive behavior - <span className="w">CASE-BY-CASE</span>
                    <br />•  Multi-Account usage/Smurfing - <span className="w">DISQUALIFICATION</span>
                    <br />•  Match-fixing/teaming - <span className="w">DISQUALIFICATION</span>
                    <br />•  Account sharing - <span className="w">DISQUALIFICATION</span>
                    <br />•  Knowingly playing with someone who is account sharing - <span className="w">DISQUALIFICATION</span>
                    <br />•  Cheating - <span className="w">DISQUALIFICATION</span>
                    <br />•  Knowingly playing with a cheater - <span className="w">DISQUALIFICATION</span>


                    <br /><br />1. Maximum of 10minutes time will be given to join, if the team or one of its players takes more than 10minutes extra than the informed schedule time, the team will be disqualified and walkover will be given to  opponents.
                                <br />2. Decision of admins is final and every team must abide by that. Admins have the power to make a decision/modify decision based on the circumstance.
                                <br />3. When cheating, trash talking or chat spamming is uncovered in the launchpad valorant tournament, the result(s) of the match(es) in question will be voided. The player will be disqualified, forfeit his prize money, no refund of entry fees



                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Gaming;