import React from "react";
import "./Launch_Events.css"

const Models = (props) => {
    return (
        <div style={{display:"flex", flexDirection:"column", margin:"20px", width: "30%"}}>
            <img src={props.pic} className="events_pic"/>
            <div className="events_h_name"> {props.name} </div>
            <div className="events_description" style={{color:"var(--color-5)", fontWeight:"500"}}> {props.description} </div>
        </div>
    )
}

export default Models