import React, { Component } from "react"
import "./Menubar.css"
import { NavLink, Switch, Route } from "react-router-dom"
import Home from "../Home/Home"
import Contact from "../Contacts/Contacts"
import Launchpad from "../Launchpad/Launchpad"
import Drive from "../Internship Drive/Internship Drive"
import Slide from "react-reveal/Slide"

class SideDrawer extends Component {
    state = {
        drawerOpen: false
    }
    render() {
        const openHandler = () => {
            this.setState({ drawerOpen: true })
        }
        var sidedisplay = <span style={{position: "relative", top: "10px", right:"10px", fontSize: "30px", cursor: "pointer", color:"var(--color-2)" }} onClick={openHandler} >
            &#9776;</span>
        const closeHandler = () => {
            this.setState({ drawerOpen: false })
        }
        if (this.state.drawerOpen) {
            sidedisplay = <ul className="sidenav">
                <div style={{display:"flex", alignContent:"baseline"}}>
                    <img src={require("../Assets/e_logo.webp")}  className="logostyle" />
                <i className="fa fa-close closebtn" style={{ cursor: "pointer" }} onClick={closeHandler}></i>
                </div>
                
                <NavLink to="/" exact className="slink" activeClassName="borderline"><li>Home</li></NavLink>
                <NavLink to="/programs" exact className="slink" activeClassName="borderline"><li>E-Connect</li></NavLink>
                <NavLink to="/e-summit" exact className="slink" activeClassName="borderline"><li>E-Summit</li></NavLink>
                <NavLink to="/contacts" exact className="slink" activeClassName="borderline"><li>About Us</li></NavLink>    
            </ul>
        }
        return (
            <div className="appearance">
                <Slide right>
                    {sidedisplay}
                </Slide>
            </div>
        )
    }
}

export default SideDrawer; 
