import firebaseRef from '../firebaseRef'
import axios from 'axios'
import Dashboard from "./Dashboard"
import React, {useEffect} from "react";
import Login from './Login';
import {REQUEST_URL} from '../config'

function AppUtil() {

  const [emailError, setEmailError] = React.useState('')
  const [passwordError, setPasswordError] = React.useState('')
  const [user, setUser] = React.useState('')

//   function clearInputs() {
//     setEmail('')
//     setPassword('')
//   }

  function clearInputErrors() {
    setEmailError('')
    setPasswordError('')
  }

  function onLogin(email, password) {
    clearInputErrors()
    console.log(email, " ", password)
    firebaseRef.auth().signInWithEmailAndPassword(email, password).catch((error) => {
      switch (error.code) {
        case "auth/invalid-email":
        case "auth/user-disabled":
        case "auth/user-not-found":
          setEmailError(error.message)
          break
        case "auth/invalid-password":
          setPasswordError(error.message)
          break
        default:
            setPasswordError('Invalid credentials')
      }
    })
  }

  function onCreateAcc(name, email, password, contact, yos, college, city) {
    clearInputErrors()
    console.log(email, " ", password)
    firebaseRef.auth().createUserWithEmailAndPassword(email, password).catch((error) => {
      switch (error.code) {
        case "auth/email-already-in-use":
        case "auth/invalid-email":
          setEmailError(error.message)
          break
        case "auth/weak-password":
          setPasswordError(error.message)
          break
        default:
            setPasswordError('Unexpected error')
      }
    }).then(() => {
                    var user = firebaseRef.auth().currentUser;
                    axios.post(`${REQUEST_URL}/account/createAccount`, {
                        id: user.uid,
                        email: user.email,
                        name: name,
                        contact: contact,
                        accountType: 1,
                        yearOfStudy: yos,
                        college: college,
                        city: city
                    }).then(() => {
                        alert('signup complete')
                    })
                })
  }

  function sendResetEmail(email) {
    var auth = firebaseRef.auth();

    auth.sendPasswordResetEmail(email).then(function() {
      // Email sent.
    }).catch(function(error) {
      // An error happened.
    });
  }

  function authListener() {
    firebaseRef.auth().onAuthStateChanged((user) => {
        if (user) {
          setUser(user)
          console.log(user)
        } else {
          setUser('')
        }
    })
  }

  useEffect(() => {
    authListener()
  }, [])

  function ShowPage() {
    if (user) {
        return <Dashboard/>
    } else {
        return <Login 
                onLogin = {onLogin}
                onCreateAcc = {onCreateAcc}
                emailError = {emailError}
                passwordError = {passwordError}
                setResetEmail = {sendResetEmail}
            />
    }
  }

  return (
    <div className="App">
      <ShowPage />
    </div>
  );
}

export default AppUtil;
