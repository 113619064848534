// import React, { Component } from "react"
// import {REQUEST_URL} from '../config'
import "./Login.css";
// import firebaseRef from '../firebaseRef'
// import axios from 'axios'
// import {Redirect} from 'react-router-dom'

//import logo from './logo.svg';
import "../App.css";
import React from "react";
import Footer from "../Footer/Footer";

function Login(props) {
  const {
    onLogin,
    onCreateAcc,
    emailError,
    passwordError,
    sendResetEmail,
  } = props;

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [name, setName] = React.useState("");
  const [college, setCollege] = React.useState("");
  const [yos, setYos] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [city, setCity] = React.useState("");

  function onLoginPress(event) {
    event.preventDefault();
    onLogin(email, password);
  }

  function onCreateAccPress(event) {
    event.preventDefault();
    onCreateAcc(name, email, password, contact, yos, college, city);
  }

  function clickSignUp() {
    const container = document.getElementById("container");
    container.classList.add("right-panel-active");
  }

  function clickSignIn() {
    const container = document.getElementById("container");
    container.classList.remove("right-panel-active");
  }

  function clickForgotPassword(event) {
    event.preventDefault();
    sendResetEmail(email);
  }

  return (
    <div>
      <div className="lg_page1">
        <div className="container" id="container">
          <div className="form-container sign-up-container">
            <div>
              <form onSubmit={onCreateAccPress}>
                <p className="scrollmsg3">&lt;--Scroll to Sign in</p>
                <div className="headx">Create Account</div>
                <input
                  required={true}
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                />
                <input
                  required={true}
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
                <div style={{ fontSize: "16px" }}>
                  Register with the same email ID you bought the ticket
                </div>
                <input
                  required={true}
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />

                <input
                  required={true}
                  type="text"
                  value={college}
                  onChange={(e) => setCollege(e.target.value)}
                  placeholder="College name"
                />
                <input
                  required={true}
                  type="text"
                  value={yos}
                  onChange={(e) => setYos(e.target.value)}
                  placeholder="Year of study"
                />
                <input
                  required={true}
                  type="text"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  placeholder="Contact Number"
                />
                <input
                  required={true}
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  placeholder="City"
                />
                <button className="button2">Sign Up</button>
              </form>
            </div>
          </div>
          <div className="form-container sign-in-container">
            <form onSubmit={onLoginPress}>
              <p className="scrollmsg4">Scroll to Sign up--&gt;</p>
              <div className="signin">Sign in </div>

              <input
                required={true}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
              <p className="error">{emailError}</p>
              <input
                required={true}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
              <p className="error">{passwordError}</p>
              <p className="forgotpw">
                <a onClick={(e) => clickForgotPassword(e)}>
                  Forgot your password?
                </a>
              </p>
              <button className="button2">Sign In</button>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <p className="scrollmsg1">Scroll to Sign up --&gt; </p>
                <h1>Welcome!</h1>
                <div style={{ fontSize: "15px" }}>
                  To keep connected with us please login with your info
                </div>
                <button className="ghost" id="signIn" onClick={clickSignIn}>
                  Sign In
                </button>
              </div>
              <div className="overlay-panel overlay-right">
                <p className="scrollmsg2"> &lt;--Scroll to Sign in</p>
                <h1>Not registered?</h1>
                <div style={{ fontSize: "15px" }}>
                  Enter your details and take a step further to becoming an
                  intern.
                </div>
                <button className="ghost" id="signUp" onClick={clickSignUp}>
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Login;

// class Login extends Component {

//     state = {
//         name: "",
//         email: "",
//         password: "",
//         college: "",
//         yos: "",
//         contact: ""
//     }

//     onLoginPress(event) {
//         event.preventDefault()
//         this.onLogin(this.state.email, this.state.password)
//     }

//     onLogin(email, password) {
//         firebaseRef.auth().signInWithEmailAndPassword(email, password).catch((error) => {
//             switch (error.code) {
//                 case "auth/invalid-email":
//                 case "auth/user-disabled":
//                 case "auth/user-not-found":
//                     alert(error.message)
//                     break
//                 case "auth/invalid-password":
//                     alert(error.message)
//                     break
//                 default:
//                     alert('Unexpected error')
//               }
//         })
//     }

//     onCreateAccountPress(event) {
//         event.preventDefault()
//         this.onCreateAccount(this.state.email, this.state.password, this.state.college, this.state.contact, this.state.yos)
//     }

//     onCreateAccount(email, password) {
//         firebaseRef.auth().createUserWithEmailAndPassword(email, password).catch((error) => {
//             switch (error.code) {
//                 case "auth/invalid-email":
//                 case "auth/user-disabled":
//                 case "auth/user-not-found":
//                     alert(error.message)
//                     break
//                 case "auth/invalid-password":
//                     alert(error.message)
//                     break
//                 default:
//                     alert('Unexpected error')
//               }
//         }).then(() => {
//             var user = firebaseRef.auth().currentUser;
//             axios.post(`${REQUEST_URL}/account/createAccount`, {
//                 id: user.uid,
//                 email: user.email,
//                 name: this.state.name,
//                 contact: this.state.contact,
//                 accountType: 1,
//                 yearOfStudy: this.state.yos,
//                 college: this.state.college
//             }).then(() => {
//                 alert('signup complete')
//             })
//         })
//     }

//     clickSignUp() {
//         const container = document.getElementById('container');
//         container.classList.add("right-panel-active");
//     }

//     clickSignIn() {
//         const container = document.getElementById('container');
//         container.classList.remove("right-panel-active");
//     }

//     render () {
//         return (
//             <div className="container" id="container">
//             <div className="form-container sign-up-container">
//                 <form onSubmit={(e) => this.onCreateAccountPress(e)}>
//                     <div className="head">Create Account</div>

//                     <input
//                         type="text"
//                         value={this.state.name}
//                         onChange={(event) => this.setState({
//                             name: event.target.value
//                         })}
//                         placeholder="Name" />
//                     <input
//                         type="email"
//                         value={this.state.email}
//                         onChange={(event) => this.setState({
//                             email: event.target.value
//                         })}
//                         placeholder="Email"/>
//                     <input
//                         type="password"
//                         value={this.state.password}
//                         onChange={(event) => this.setState({
//                             password: event.target.value
//                         })}
//                         placeholder="Password" />
//                     <input
//                         type="text"
//                         value={this.state.college}
//                         onChange={(event) => this.setState({
//                             college: event.target.value
//                         })}
//                         placeholder="College name" />
//                     <input
//                         type="text"
//                         value={this.state.yos}
//                         onChange={(event) => this.setState({
//                             yos: event.target.value
//                         })}
//                         placeholder="Year of study" />
//                     <input
//                         type="number"
//                         value={this.state.contact}
//                         onChange={(event) => this.setState({
//                             contact: event.target.value
//                         })}
//                         placeholder="Contact Number" />
//                     <button className="button" >Sign Up</button>

//                 </form>
//             </div>
//             <div className="form-container sign-in-container">
//                 <form onSubmit={(e) => this.onLoginPress(e)}>
//                     <div className="signin">Sign in</div>

//                     <input
//                         type="email"
//                         value={this.state.email}
//                         onChange={(event) => this.setState({
//                             email: event.target.value
//                         })}
//                         placeholder="Email" />
//                     <input
//                         type="password"
//                         value={this.state.password}
//                         onChange={(event) => this.setState({
//                             password: event.target.value
//                         })}
//                         placeholder="Password" />
//                     <a href="#">Forgot your password?</a>
//                     <button className="button1">Sign In</button>
//                 </form>
//             </div>
//             <div className="overlay-container">
//                 <div className="overlay">
//                     <div className="overlay-panel overlay-left">
//                         <h1>Welcome!</h1>
//                         <p>To keep connected with us please login with your info</p>
//                         <button className="ghost" id="signIn" onClick={this.clickSignIn}>Sign In</button>
//                     </div>
//                     <div className="overlay-panel overlay-right">
//                         <h1>Not registered?</h1>
//                         <p>Enter your details and take a step further to becoming an intern.</p>
//                         <button className="ghost" id="signUp" onClick={this.clickSignUp}>Sign Up</button>
//                     </div>
//                 </div>
//             </div>
//         </div>

//         )
//     }
// }

// export default Login;
