import React from "react"
import "./Home.css"
import { Link } from "react-router-dom"
import Models from "./SubPages/Models"
import Projects from "../Storage/Projects.json"
import Competitions from "../Storage/Competitions.json"
import Guests from "../Storage/Guests.json"
import GuestPersons from "../Launchpad/Events/GuestPersons"
import Footer from "../Footer/Footer"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"

const Home = () => {
    return (
        <div style={{ position: "absolute", zIndex: "-10" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex" }} className="title1">
                    <div class="wrapper">
                        <h1><span class="text_1">E-CELL BITS HYDERABAD</span></h1>
                        <div className="page1_sub">
                            Embibing the spirit of entrepreneurship
                        </div>
                    </div>
                </div>
                <div className="title2">
                    <div style={{display:"flex", justifyContent:"center", flexWrap:"wrap"}}>
                    <div>
                        <Link to="/programs"><img className="initiative e-connect" src={require("../Assets/Launchpad Events/EC.webp")} /></Link>
                        <div style={{ textAlign: "center", color: "var(--color-2)" }} className="sub_heading"> Programs</div>
                    </div>
                    <div>
                        <Link to="/e-summit"><img className="initiative e-connect" src={require("../Assets/Launchpad Events/ES.webp")} /></Link>
                        <div style={{ textAlign: "center", color: "var(--color-2)" }} className="sub_heading"> E-Summit </div>
                    </div>
                    <div>
                        <Link to="/competitions"><img className="initiative e-connect" src={require("../Assets/Launchpad Events/CC.webp")} /></Link>
                        <div style={{ textAlign: "center", color: "var(--color-2)" }} className="sub_heading"> Competitions </div>
                    </div>
                    <div>
                        <Link to="/internship-drive"><img className="initiative e-connect" src={require("../Assets/Launchpad Events/DV.webp")} /></Link>
                        <div style={{ textAlign: "center", color: "var(--color-2)" }} className="sub_heading">Internship Drive </div>
                    </div>
                    <div>
                        <Link to="/competitions"><img className="initiative e-connect" src={require("../Assets/Icon/S Support 2.webp")} /></Link>
                        <div style={{ textAlign: "center", color: "var(--color-2)" }} className="sub_heading"> Start Up Support </div>
                    </div>
                    <div >
                        <Link to="/peer to peer"><img className="initiative e-connect" src={require("../Assets/Icon/Peer.webp")} /></Link>
                        <div style={{ textAlign: "center", color: "var(--color-2)" }} className="sub_heading"> Peer to Peer </div>
                    </div>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: "var(--color-1)", display: "flex", justifyContent: "center", flexWrap: "wrap", marginTop: "-10px" }}>
                
            </div>
            <div style={{ display: "none", flexDirection: "column" }}>
                <div className="home_headings"> COMPETITIONS </div>
                <div style={{ display: "flex", flexWrap: "wrap", marginBottom: "100px" }}>
                    {Competitions.map((Mapping) => {
                        return (
                            <Models
                                key={Mapping.name}
                                name={Mapping.name}
                                description={Mapping.description}
                                pic={require("../Assets/Home" + Mapping.pic)} />
                        )
                    })}
                </div>
            </div>
            <div style={{ display: "none", flexDirection: "column" }}>
                <div className="home_headings"> Projects </div>
                <div style={{ display: "flex" }}>
                    {Projects.map((Mapping) => {
                        return (
                            <Models
                                key={Mapping.name}
                                name={Mapping.name}
                                description={Mapping.description}
                                pic={require("../Assets/Home" + Mapping.pic)} />
                        )
                    })}
                </div>
            </div>
            
            <div style={{ display: "flex", backgroundColor: "var(--color-3)" }}>

                <div style={{ display: "none", marginTop: "50px" }}>
                    <Carousel width="450px" infiniteLoop autoPlay useKeyboardArrows>
                        {
                            Guests.map((Mapping) => {
                                return (
                                    <GuestPersons
                                        key={Mapping.name}
                                        name={Mapping.name}
                                        comments={Mapping.comments}
                                        post={Mapping.post}
                                        pic={require("../Assets/Guests" + Mapping.pic)} />
                                )
                            })
                        }
                    </Carousel>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default Home;