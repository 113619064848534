import React from "react"
import "./Sponsors.css"
import Footer from "../../Footer/Footer"
import Carousel from 'react-bootstrap/Carousel'
const Sponsors = (props) => {
return (
        <div>
            <div className="sp_page1">
                <div className="wrapper">
                    <h1  style={{ color: "var(--color-1)"}}>
                        <span className="text_1">OUR SPONSORS</span></h1>
                </div>
            </div>
            
            <div className="sp_page3" >
                <div style={{color:"white", fontFamily:"Roboto, sans-serif"}} className="reg_head">SPONSORS FOR LAUNCHPAD 2021</div>
                
                <div style={{border:"2px dashed white", borderRadius:"50px", padding:"20px", paddingBottom:"0"}}>
                
                <Carousel style={{height:"400px" , width:"640px"}}>                
                <Carousel.Item style={{height:"360px" , width:"640px"}}>
                    <img
                    className="d-block w-100"
                    src={require("./tbi.png")}
                    style={{borderRadius:"50px"}}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item style={{height:"360px" , width:"640px"}}>
                    <img
                    className="d-block w-100"
                    src={require("./associate.png")} 
                    style={{borderRadius:"50px"}}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item style={{height:"360px" , width:"640px"}}>
                    <a href="https://www.knowello.com/">
                    <img
                    className="d-block w-100"
                    src={require("./kw.png")}
                    style={{borderRadius:"50px"}}
                    /></a>
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    </Carousel.Item>
                <Carousel.Item style={{height:"360px" , width:"640px"}}>
                    <a href="https://www.mygoalstreet.com/">
                    <img
                    className="d-block w-100"
                    src={require("./gs.png")}
                    style={{borderRadius:"50px"}}
                    /></a>
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{height:"360px" , width:"640px"}}>
                    <a href="https://www.daretostart.org/">
                    <img
                    className="d-block w-100"
                    src={require("./dts.png")}
                    style={{borderRadius:"50px"}}
                    /></a>
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{height:"360px" , width:"640px"}}>
                    <a href="https://fanclash.in/" >
                    <img
                    className="d-block w-100"
                    src={require("./fc.png")}
                    style={{borderRadius:"50px"}}
                    /></a>
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    </Carousel.Item>
                    
                    <Carousel.Item style={{height:"360px" , width:"640px"}}>
                    <img
                    className="d-block w-100"
                    src={require("./io.png")}
                    style={{borderRadius:"50px"}}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{height:"360px" , width:"640px"}}>
                    <img
                    className="d-block w-100"
                    src={require("./SPONSORS.png")}
                    style={{borderRadius:"50px"}}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{height:"360px" , width:"640px"}}>
                    <img
                    className="d-block w-100"
                    src={require("./SPONSORS (1).png")}
                    style={{borderRadius:"50px"}}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{height:"360px" , width:"640px"}}>
                    <img
                    className="d-block w-100"
                    src={require("./SPONSORS (2).png")}
                    style={{borderRadius:"50px"}}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{height:"360px" , width:"640px"}}>
                    <a href="https://fablabs.in/"><img
                    className="d-block w-100"
                    src={require("./SPONSORS (3).png")}
                    style={{borderRadius:"50px"}}
                    /></a>
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{height:"360px" , width:"640px"}}>
                    <a href="https://aws.amazon.com/">
                    <img
                    className="d-block w-100"
                    src={require("./SPONSORS (4).png")}
                    style={{borderRadius:"50px"}}
                    /></a>
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{height:"360px" , width:"640px"}}>
                    <a href="https://www.servcorp.com/en/">
                    <img
                    className="d-block w-100"
                    src={require("./SPONSORS (5).png")}
                    style={{borderRadius:"50px"}}
                    /></a>
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{height:"360px" , width:"640px"}}>
                    <a href="https://huddle.work/">
                    <img
                    className="d-block w-100"
                    src={require("./SPONSORS (6).png")}
                    style={{borderRadius:"50px"}}
                    /></a>
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    </Carousel.Item>
                    </Carousel>
                </div>
            </div>
            <div className="sp_page2" >
                <div style={{color:"white", fontFamily:"Roboto, sans-serif"}} className="reg_head">PREVIOUS EDITION SPONSORS</div>
                
                <div>
                
                <Carousel style={{height:"660px" , width:"600px"}}>                
                <Carousel.Item style={{height:"600px" , width:"600px"}}>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Sponsors/TT.webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{height:"600px" , width:"600px"}}>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Sponsors/TBU.webp")}                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>                  
                </Carousel.Item>
                <Carousel.Item style={{height:"600px" , width:"600px"}}>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Sponsors/BYJU.webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{height:"600px" , width:"600px"}}>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Sponsors/AP1.webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{height:"600px" , width:"600px"}}>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Sponsors/AP2.webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{height:"600px" , width:"600px"}}>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Sponsors/AP3.webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{height:"600px" , width:"600px"}}>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Sponsors/AP4.webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{height:"600px" , width:"600px"}}>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Sponsors/IP.webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{height:"600px" , width:"600px"}}>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Sponsors/INCUBATION.webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{height:"600px" , width:"600px"}}>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Sponsors/TP.webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    </Carousel.Item>
                    </Carousel>
                </div>
            </div>
            <Footer />
            </div>
    )
}

export default Sponsors;