import React, { Component } from "react"
import "./Media.css"

class Media extends Component {
    render() {
        return (
            <div>
                <div style={{ display: "flex" }}>
                    <img src={require("../Assets/Big Launch.gif")} style={{ width: "100%" }} />
                </div>
                <div style={{display:"flex", justifyContent:"center", flexWrap:"wrap"}}>
                    <img className="blog_thumbnail" src={require("../Assets/Home/BP.webp")} />
                    <img className="blog_thumbnail" src={require("../Assets/Home/BP.webp")} />
                    <img className="blog_thumbnail" src={require("../Assets/Home/BP.webp")} />
                    <img className="blog_thumbnail" src={require("../Assets/Home/BP.webp")} />
                    <img className="blog_thumbnail" src={require("../Assets/Home/BP.webp")} />
                    <img className="blog_thumbnail" src={require("../Assets/Home/BP.webp")} />
                    <img className="blog_thumbnail" src={require("../Assets/Home/BP.webp")} />
                    <img className="blog_thumbnail" src={require("../Assets/Home/BP.webp")} />
                    <img className="blog_thumbnail" src={require("../Assets/Home/BP.webp")} />
                </div>
            </div>
        )
    }
}

export default Media;