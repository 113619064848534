import React from "react"
import "../GR/GR.css"
import Footer from "../../Footer/Footer"
import "./competitions.css"
import {Link} from "react-router-dom"

const Competitions = () => {
    return (
        <div>
            <div className="se_page p1">
                <div style={{ transform: "translate(0%, 30%)" }} class="wrapper">
                    <h1><span class="text_1">VALORANT<br />TOURNAMENT</span></h1>
                    <div  className="page1_sub">
                    A test of Competitive Spirit
                        </div>
                    <button style={{ backgroundColor: "var(--color-1)", margin: "0px", width: "fit-content"}} className="id_login">
                       <a style={{textDecoration:"none", color:"var(--color-2)"}} href="/valorant">READ MORE</a></button>
                </div>
            </div>
            <div className="se_page p2">
                <div style={{ transform: "translate(0%, 50%)" }} class="wrapper">
                    <h1><span class="text_1">ENTREQUIZTA</span></h1>
                    <div style={{fontFamily:"Montserrat, sans-serif"}} className="page1_sub quizz"> Test your entrepreneurship knowledge </div>
                    <button style={{ backgroundColor: "var(--color-1)", margin: "0px", width: "fit-content" }} className="id_login">
                       <a style={{color:"var(--color-2)", textDecoration:"none"}} href="/quiz" > READ MORE </a></button>
                </div>
            </div>
            <div style={{display:"none"}} className="se_page p3">
                <div style={{ transform: "translate(0%, 50%)" }} class="wrapper">
                    <h1><span class="text_1" style={{color:"black"}}>SAFL</span></h1>
                    <div  className="page1_sub" style={{color:"black"}}>
                    Caption
                        </div>
                    <button style={{ backgroundColor: "var(--color-1)", margin: "0px", width: "fit-content"}} className="id_login">
                        <a style={{ textDecoration: "none", color: "var(--color-2)" }}>
                            READ MORE</a></button>
                </div>
            </div>
            <div style={{ display: "none" }} className="btn_div">
                <div className="heading_main"> STARTUP EXPO </div>
                <div><button className="btn"><p href="#form"> Register Here </p></button></div>
            </div>
            <div style={{ display: "none" }} className="Description" id="Description">
                <div className="Description1">
                    <div className="what heading">
                        Startup Expo text
				    </div>
                    <div className="what_content">
                        startupexpo text
				    </div>
                </div>
            </div>
            <div style={{ display: "none" }} className="Description2">
                <div className="what_heading">
                    text related to StartupExpo
			    </div>
                <div className="vision_content">
                    Startup Expo text
			    </div>
            </div>
            <Footer />
        </div>

    )
}

export default Competitions;