import React from "react"
import "./GuestPersons.css"

const GuestPersons = (props) => {
    return (
        <div style={{marginBottom:"100px"}}>
            <div className="guest_post" style={{fontSize:"20px"}}> {props.comments} </div>
            <div style={{display:"flex", marginTop: "50px"}}>
                <div>
                    <img src={props.pic} className="guest_image"/>
                </div>
                <div style={{display:"flex", flexDirection:"column", marginLeft: "20px"}}>
                    <div className="guest_name"> {props.name} </div>
                    <div className="guest_post"> {props.post} </div>
                </div>
            </div>
        </div>
    )
}

export default GuestPersons;