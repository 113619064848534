import React from "react"
import "./Speakers.css"
import Footer from "../../Footer/Footer"
import Carousel from 'react-bootstrap/Carousel'
const Speakers = (props) => {
    return (
        <div>
            <div style={{ display: "flex" }} className="sp_p1">
                    <div class="wrapper">
                        <h1><span class="text_1">OUR GUESTS</span></h1>
                    </div>
            </div>
            <div className="sp_p3">
            <div className="reg_head">SPEAKERS</div>
            <div>
            <Carousel  style={{height:"400px", width:"300px"}}> 
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Guests/VarunS.webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    <p>VARUN SRIDHAR<br></br>CEO, Paytm Money</p>
                    </Carousel.Caption>   
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Guests/sj.webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    <p>SAMBHAV JAIN<br></br>Founder, FamPay</p>
                    </Carousel.Caption>   
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Guests/mm.webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    <p>MAJD MAKSAD<br></br>Founder & CEO, Status Money</p>
                    </Carousel.Caption>   
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Guests/sk.webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    <p>SHREHITH KARKERA<br></br>Co-Founder, FinShots.in</p>
                    </Carousel.Caption>   
                </Carousel.Item>
                
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Guests/vk.webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    <p>VANI KOLA<br></br>Founder & MD, Kalaari Capital</p>
                    </Carousel.Caption>   
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Guests/SP.webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    <p>SAM PITRODA<br></br>Father Of India's IT Revolution</p>
                    </Carousel.Caption>
                </Carousel.Item>               
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Guests/ss.webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    <p>SHRADHA SHARMA <br></br>Founder & CEO, YourStory</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Guests/sb.webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    <p>SANJEEV BIKHCHANDANI <br></br>Founder, Naukri.com</p>
                    </Carousel.Caption>
                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Guests/S-S.webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    <p>SANJAY SETHI <br></br>Co-Founder & CEO, Shopclues.com</p>
                    </Carousel.Caption>
                    
                </Carousel.Item>                    
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Guests/vn.webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    <p>VINEET NAYAR <br></br>Founder & Chairman, Sampark Foundation</p>
                    </Carousel.Caption>
                  
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Guests/vs.webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    <p>VIVEK SUNDAR<br></br> COO, Swiggy</p>
                    </Carousel.Caption>
                    
                </Carousel.Item>
                
        </Carousel>
            </div>
            </div>
            <div className="sp_p2">
            <div className="reg_head">PREVIOUS EDITION SPEAKERS</div>
            <div>
            <Carousel style={{height:"540px", width:"960px"}}>                
                <Carousel.Item >
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Guests/1(1).webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Guests/2 (1).webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                  
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Guests/3 (1).webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Guests/4 (1).webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Guests/5 (1).webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Guests/6 (1).webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Guests/7 (1).webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                       
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Guests/8 (1).webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                        
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={require("../../Assets/Guests/9 (1).webp")}
                    />
                    <Carousel.Caption style={{position:"relative" , left:"auto",right:"auto"}}>
                    
                    </Carousel.Caption>
                    
                </Carousel.Item>
        </Carousel>
            </div>
            </div>
            
            <Footer />
        </div>
    )
}

export default Speakers;