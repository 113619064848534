var firebaseConfig = {
    apiKey: "AIzaSyBjUdhGuCDOyyUIOwf7j1W_g77UCdGs-7o",
    authDomain: "e-cell-website-bpc.firebaseapp.com",
    databaseURL: "https://e-cell-website-bpc.firebaseio.com",
    projectId: "e-cell-website-bpc",
    storageBucket: "e-cell-website-bpc.appspot.com",
    messagingSenderId: "948844627295",
    appId: "1:948844627295:web:23c7e3ad4417241d8ce2ea",
    measurementId: "G-5FRCHNQQB2"
  };
  
export default firebaseConfig;