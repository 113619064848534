import React from "react";
import "./merged.css";
import "./appliedcompany.css";

import Tesla from "./tesla-logo-font-download-1200x675.jpg";

function AppliedCompany(props) {
  const { companies } = props;
  console.log("companies", companies);

  return (
    <div>
      <div className="maincontainer maincontainer2">
        <div className="container22">
          {companies ? (
            <ul className="of">
              {companies.map((job) => (
                <li className="boxx" key={job.id}>
                  <div className="imagenew1">
                    <img
                      src={require(`../../Assets/ID logos/${job.srcUrl}`)}
                      alt="Italian Trulli"
                      style={{ margin: 10, width: "95%", height: "auto" }}
                    />
                  </div>
                  <div className="andarka andarka2">
                    <div className="head head2">{job.companyName}</div>
                    {/* <div className="datey">Date : 20-january-2021</div> */}
                  </div>
                  <div className="button1">
                    <i className="fa fa-check" style={{ marginRight: 7 }}></i>
                    APPLIED
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div class="loader"></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AppliedCompany;
