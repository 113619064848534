import React from "react"
import Footer from "../../Footer/Footer"
import "./Workshop.css"
import Toolbar from "../../Navigation/Toolbar"
import Slide from "react-reveal/Slide"
const Workshop = () => {

    return (
        <div style={{overflow:"scroll"}}>
            <Toolbar />
        <div className="ws_1">
                    <div style={{ transform: "translate(0%, 50%)" , width:"fit-content"}} class="wrapper">
                        <h1><span class="text_1">PRODUCT MANAGEMENT <br />WORKSHOP</span></h1>
                        <div className="page1_sub" style={{fontSize:"20px", padding:"5px"}}>
                            Basics To Advance
                        </div>
                        <button style={{backgroundColor:"var(--color-1)", width:"fit-content", margin:"5px"}} className="id_login">
                            <a style={{textDecoration:"none",  color:"var(--color-2)"}} href="https://www.meraevents.com/event/launchpad-2021-5th-edition?ucode=organizer" target="_blank">
                            VIEW OTHER EVENTS</a></button>
                    </div>
        </div>
             <div className="ws_2" style={{ backgroundAttachment: "fixed" }}>
                        <div className="x1" style={{flexBasis:"1"}}>
                    <Slide left>
                        <img style={{ width: "100%" , height: "30vh"}} src={require("../PM Workshop/x1.jpg")} />
                    </Slide>
                    </div>
                    <div className="x1" style={{flexBasis:"1"}}>
                    <Slide right>
                        <img style={{ width: "100%" , height: "30vh"}} src={require("../PM Workshop/x2.jpg")} />
                    </Slide>
                    </div>
            </div>
                
                <div className="form_back">
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScCQ_wBMTcAi2gYFcdJavLZtp4WCmKorZR5sfly-AOD7qIFqw/viewform?embedded=true" width="640" height="780" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                </div>
                <Footer />
                </div>
        )
}

export default Workshop;