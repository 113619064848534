import React from 'react'
import './dashboard.css'
import './merged.css'
//import './cdnjs.css'

function Dashboardcomplete(props) {
    const { profile } = props; 
    return (
            <div className="outercontainer">
                <div className="uppercontainer">
                    <div className="names">
                        <div className="namenew">{profile.name}</div>
                        <div className="namenewlow">{profile.bio}</div>
                    </div>  

                </div>
                <div className="cl1">
                    
                        <div className="boxnew"><div className="titlenew">Companies Applied</div><div className="number">{profile.applied}</div> </div>
                        <div className="boxnew"><div className="titlenew">Available companies</div><div className="number">{27}</div></div>
                        <div className="boxnew"><div className="titlenew"> Application Limit</div><div className="number">{6}</div></div>    
                    
                <div className="baaki">You can apply in '{6-profile.applied}' more companies</div>
                <div className="note">
                    <div className="note_head">NOTE :</div>
                    <div className="note_content">
                       <div className="points">1. More companies will be added on the portal soon, so keep checking the Dashboard.</div>
                       <div className="points">2. Once you have applied to a company, your application cannot be reverted back in any circumstances, 
                       thus apply carefully.</div>
                    </div>
                    </div>
                </div>
            </div>
    )
}

export default Dashboardcomplete
