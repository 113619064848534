import React from "react";
import "./Community.css";
import Footer from "../Footer/Footer";

const Community = () => {
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}
        className="cm_pg1">
        <div class="wrapper">
          <h1>
            <span class="text_1">ENTREPRENEURIAL MINDS</span>
          </h1>
          <a style={{ textDecoration: "none", color: "var(--color-2)" }}
              href="https://join.slack.com/t/entrepreneurial-mind1/shared_invite/zt-lxoptuiu-EMVCkOzcxbzN1QezIxNmlw"
              target="_blank">
          <button className="id_login register-button">
            JOIN THE COMMUNITY
          </button>
          </a>
        </div>
      </div>
      <div
        style={{ display: "flex", flexDirection: "column" }}
        className="cm_pg2"
      >
        <div style={{ color: "white" }} className="reg_head">
          HOW IT LOOKS
        </div>
        <img
          style={{ width: "70%", borderRadius: "20px" }}
          src={require("../Assets/Backgrounds/EM.webp")}
        />
      </div>

      <Footer />
    </div>
  );
};

export default Community;
