import React, { Component } from "react";
import "./Toolbar.css";
import { NavLink, Switch, Route } from "react-router-dom";
import Home from "../Home/Home";
import Contact from "../Contacts/Contacts";
import Launchpad from "../Launchpad/Launchpad";
import Drive from "../Internship Drive/Internship Drive";
import Media from "../Media/Media";
import SlideDrawer from "./Menubar";

class Toolbar extends Component {
  state = {
    bgcolor: "transparent",
    home: false,
    initiative: false,
    launchpad: false,
  };
  componentDidMount() {
    document.addEventListener("scroll", () => {
      var changecolor = " ";
      if (window.scrollY > 200) {
        changecolor = "black";
        this.setState({ bgcolor: changecolor });
      }
      if (window.scrollY < 200) {
        changecolor = "transparent";
        this.setState({ bgcolor: changecolor });
      }
    });
  }
  render() {
    const menu_opener = (tab) => {
      switch (tab) {
        case "Home":
          this.setState({ home: true, launchpad: false, initiative: false });
          break;
        case "Launchpad":
          this.setState({ launchpad: true, home: false, initiative: false });
          break;
        case "Initiative":
          this.setState({ initiative: true, home: false, launchpad: false });
          break;
      }
      if (this.state.home) {
        document.querySelector(".submenu1").style.display = "none";
        document.querySelector(".submenu2").style.display = "none";
        document.querySelector(".submenu3").style.display = "none";
      }
      if (this.state.initiative) {
        document.querySelector(".submenu1").style.display = "none";
        document.querySelector(".submenu2").style.display = "none";
        document.querySelector(".submenu3").style.display = "none";
      }
      if (this.state.launchpad) {
        document.querySelector(".submenu1").style.display = "none";
        document.querySelector(".submenu2").style.display = "none";
        document.querySelector(".submenu3").style.display = "none";
      }
    };
    return (
      <div style={{ zIndex: "100" }}>
        <div>
          <NavLink to="/" exact>
            <img className="logo" src={require("../Assets/e_logo.webp")} />
          </NavLink>
        </div>
        <div
          // style={{ display: "inline" }}
          className="bar"
          style={{ backgroundColor: `${this.state.bgcolor}` }}
        >
          <div
            style={{ display: "none", margin: "25px", flexDirection: "column" }}
          >
            <div
              onMouseEnter={() => {
                menu_opener("Home");
              }}
            >
              <NavLink
                className="link"
                to="/"
                exact
                activeClassName="borderline"
              >
                Home
                <i
                  style={{ marginLeft: "10px", display: "none" }}
                  className="fa fa-caret-down"
                ></i>
              </NavLink>
            </div>
            <div style={{ display: "none" }} className="main submenu1">
              <NavLink className="link" to="/competitions">
                <div id="1"> Competitions </div>
              </NavLink>
              <NavLink className="link" to="/projects">
                <div id="2"> Projects </div>
              </NavLink>
              <NavLink className="link" to="/launchpad events">
                <div id="3"> Launchpad Events</div>
              </NavLink>
            </div>
          </div>
          <div
            style={{ display: "flex", margin: "25px", flexDirection: "column" }}
            className="navWrapper"
          >
            <div
              onMouseEnter={() => {
                menu_opener("Launchpad");
              }}
            >
              <NavLink
                activeClassName="borderline"
                className="link"
                to="/e-summit"
                exact
                //activeClassName="borderline"
              >
                E Summit
                <i
                  style={{ marginLeft: "10px", display: "none" }}
                  class="fa fa-caret-down"
                ></i>
              </NavLink>
            </div>
            <div style={{ display: "none" }} className="main submenu3">
              <NavLink className="link" to="/ground reality">
                <div id="1"> Ground Reality </div>
              </NavLink>
              <NavLink className="link" to="/beyond profits">
                <div id="2"> Beyond Profits </div>
              </NavLink>
              <NavLink className="link" to="/startup-expo">
                <div id="2"> Start Up Expo </div>
              </NavLink>
              <NavLink className="link" to="/projects">
                <div id="2"> Guest Speakers </div>
              </NavLink>
              <NavLink className="link" to="/internship-drive">
                <div id="2"> Internship Drive </div>
              </NavLink>
              <NavLink className="link" to="/launchpad events">
                <div id="3"> Previous Editions</div>
              </NavLink>
            </div>
          </div>
          <div
            style={{ display: "flex", margin: "25px", flexDirection: "column" }}
            className="navWrapper"
          >
            <div
              className="link"
              onMouseEnter={() => {
                menu_opener("Initiative");
              }}
            >
              <NavLink
                activeClassName="borderline"
                className="link"
                to="/community"
              >
                <div id="4"> Community </div>
              </NavLink>
              <i
                style={{ marginLeft: "10px", display: "none" }}
                class="fa fa-caret-down"
              ></i>
            </div>
            <div style={{ display: "none" }} className="main submenu2">
              <NavLink className="link" to="/startup support">
                <div id="5"> Startup Support </div>
              </NavLink>
              <NavLink className="link" to="/peer to peer">
                <div id="6"> Peer to Peer</div>
              </NavLink>
              <NavLink className="link" to="/multitude learning">
                <div id="6"> Multitude Learning</div>
              </NavLink>
            </div>
          </div>
          <NavLink
            style={{ display: "none" }}
            to="/media"
            className="link"
            activeClassName="borderline"
          >
            <li>Media</li>
          </NavLink>

          <NavLink
            activeClassName="borderline"
            to="/contacts"
            style={{ margin: "25px" }}
            className="link navWrapper"
            activeClassName="borderline"
          >
            <li>About Us</li>
          </NavLink>

          <NavLink 
            activeClassName="borderline"
            to="/internship-drive"
            className="linkInternship"
            activeClassName="borderline"
          >
            <li>Internship Drive</li>
          </NavLink>
          <SlideDrawer />
        </div>
      </div>
    );
  }
}

export default Toolbar;
