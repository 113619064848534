import React from "react"
import "../GR/GR.css"
import Footer from "../../Footer/Footer"
import "./expo.css"

const expo = () => {

    return (
        <div>
            <div className="se_page1">
                <div style={{ transform: "translate(0%, 50%)" }} class="wrapper">
                    <h1><span class="text_1">START-UP EXPO</span></h1>
                    <div  className="page1_sub">
                    Providing budding ventures the spotlight
                        </div>
                    <button style={{ backgroundColor: "var(--color-1)", margin: "0px" }} className="id_login">
                        <a style={{ textDecoration: "none", color: "var(--color-2)" }} href="https://www.meraevents.com/event/launchpad-2021-5th-edition?ucode=organizer" target="_blank">
                            BUY TICKETS</a></button>
                </div>
            </div>
            <div style={{ display: "none" }} className="btn_div">
                <div className="heading_main"> STARTUP EXPO </div>
                <div><button className="btn"><p href="#form"> Register Here </p></button></div>
            </div>
            <div style={{ display: "none" }} className="Description" id="Description">
                <div className="Description1">
                    <div className="what heading">
                        Startup Expo text
				    </div>
                    <div className="what_content">
                        startupexpo text
				    </div>
                </div>
            </div>
            <div style={{ display: "none" }} className="Description2">
                <div className="what_heading">
                    text related to StartupExpo
			    </div>
                <div className="vision_content">
                    Startup Expo text
			    </div>
            </div>
            <Footer />
        </div>

    )
}

export default expo;