import React from "react"
import "./Footer.css"
import {NavLink} from "react-router-dom"
const Footer = () => {
    return (
        <div style={{backgroundColor:"var(--color-6)", color:"var(--color-5)", paddingBottom:"100px"}}>
            <div className="footer">
                <div className="spacing" style={{ display: "flex", flexDirection: "column", width: "300px" }}>
                    <div className="footer_heading">
                        E-Cell BPHC
                    </div>
                    <div className="footer_subnames" style={{marginTop: "20px"}}>
                        Networking Startups All Over the World
                    </div>
                    <div>
                        <div style={{marginTop:"100px"}} className="footer_heading">
                         Follow Us
                        </div>
                        <a href=" https://www.facebook.com/eCELL.BPHC" target="_blank" class="fa fa-facebook fa1"></a>
                        <a href="https://www.instagram.com/ecell_bphc/" target="_blank" class="fa fa-instagram fa1"></a>
                        <a href="https://twitter.com/ecell_bphc" target="_blank" class="fa fa-twitter fa1"></a>
                        <a href="https://www.linkedin.com/company/e-cell-bphc" target="_blank" class="fa fa-linkedin fa1"></a>
                    </div>
                </div>
                <div className="spacing" style={{ display: "flex", flexDirection: "column" }}>
                    <div className="footer_heading">
                        Contact Us
                    </div>
                    <div style={{ display: "flex", marginTop: "20px" }}>
                        <a href="mailto:ecell@hyderabad.bits-pilani.ac.in" style={{cursor:"pointer", textDecoration:"none"}} className="footer_subnames">
                            ecell@hyderabad.bits-pilani.ac.in
                        </a>
                    </div>
                </div>
                <div className="spacing" style={{ display: "none", flexDirection: "column" }}>
                    <div className="footer_heading">
                        Blogs
                    </div>
                    <div className="footer_subnames" style={{marginTop: "20px"}}>
                        Google Stadia
                    </div>
                </div>
                <div className="spacing" style={{ display: "flex", flexDirection: "column"}}>
                    <div className="footer_heading">
                        Site Links
                    </div>
                    <div style={{ display: "flex", marginTop: "20px" }}>
                        <NavLink to="/programs" className="footer_subnames">
                            Programs
                        </NavLink>
                    </div>
                    <div style={{ display: "flex", marginTop: "20px" }}>
                        <NavLink to="/internship-drive" className="footer_subnames">
                            Internship Drive
                        </NavLink>
                    </div>
                    <div style={{ display: "flex", marginTop: "20px" }}>
                        <NavLink to="/e-summit" className="footer_subnames">
                            E-Summit
                        </NavLink>
                    </div>
                    <div style={{ display: "flex", marginTop: "20px" }}>
                        <NavLink to="/competitions" className="footer_subnames">
                            Competitions
                        </NavLink>
                    </div>
                    <div style={{ display: "flex", marginTop: "20px" }}>
                        <div className="footer_subnames">
                            Start Up Support
                        </div>
                    </div>
                    <div style={{ display: "flex", marginTop: "20px" }}>
                        <NavLink to="/peer to peer" className="footer_subnames">
                            Peer to Peer
                        </NavLink>
                    </div>
                </div>
            </div>
            <div style={{fontSize: "16px", fontFamily: "Poppins, sans-serif", textAlign:"center"}}>
                Copyright <i className="fa fa-copyright"></i> 2021 All rights reserved. Designed by E-Cell BITS
                Hyderabad
            </div>
        </div>
    )
}

export default Footer;