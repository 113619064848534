import React from "react"
import Footer from "../Footer/Footer"
import "./Econnect.css"
import Events from "../Launchpad/Events/Events"

import { submitEConnectForm } from '../services/formResponse'

const Econnect = () => {

    const [name, setName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [college, setCollege] = React.useState('')
    const [message, setMessage] = React.useState('')

    function onSubmit(event) {
        event.preventdefault()
        submitEConnectForm(name, email, college, message)
    }

    return (
        <div>
            <div className="ec_page1">
                <div class="wrapper">
                    <h1><span class="text_1">PROGRAMS</span></h1>
                    <div className="page1_sub">
                        Imbibing the spirit of entrepreneurship
                        </div>
                </div>
            </div>
            <div style={{ display: "none", justifyContent: "space-around" }}>
                <div className="what_heading">
                    What is EConnect?
                </div>
                <div className="what_content">
                    E-Connect is an initiative where we plan on reaching out
                    to various colleges and corroborate with their respective
                    E-cells to further the entrepreneurship culture together
                    and to create a strong community outside our campus too.
                    We are pursuing to guide colleges to strengthen their
                    structure of ECell, mentoring them to develop a
                    backbone of a vital center for entrepreneurship activities to be
                    able to expand the conducive environment for others to benefit.
                </div>
            </div>
            <div style={{ display: "none", justifyContent: "space-around" }}>
                <div className="vision_heading">
                    VISION
                </div>
                <div className="vision_content">
                    Connecting with E-Cells nationwide will make a
                    difference in terms of people reached, and the network
                    created will help all the E-Cells grow phenomenally
                    in the upcoming years. This helps develop a culture of learning,
                    innovation, and creativity and allows every person to reach new heights
                    of success.
                </div>
            </div>
            <div style={{ display: "none", justifyContent: "space-around" }}>
                <div>
                    <button className="connect_buttons">Excelerate</button>
                </div>
                <div>
                    <button className="connect_buttons">Entree-prenuership</button>
                </div>
            </div>
            <div style={{ display: "none" }} className="ec_page3">
                <div className="vision_heading"> PERKS</div>
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    <div className="perk_template">
                        <div className="front">
                            <div className="perk_icon">
                                <img className="perk_icon" src={require("../Assets/Icon/SS.webp")} />
                            </div>
                            <div className="perk_head">TALKS/SESSIONS</div>
                        </div>
                        <div className="perk_content back">
                            We plan to involve all our members of this program
                            to attend the amazing sessions and discussions that
                            are organized by us regularly.
                        </div>
                    </div>
                    <div className="perk_template">
                        <div className="front">
                            <div className="perk_icon">
                                <img className="perk_icon" src={require("../Assets/Icon/SA.webp")} />
                            </div>
                            <div className="perk_head">START-UP ASSISTANCE</div>
                        </div>
                        <div className="perk_content back">
                            A start-up with good potential from your campus will
                            get all necessary support from our side, including a
                            direct entry for the annual B-Plan Competition.
                        </div>
                    </div>
                    <div className="perk_template">
                        <div className="front">
                            <div className="perk_icon">
                                <img className="perk_icon" src={require("../Assets/Icon/EG.webp")} />
                            </div>
                            <div className="perk_head">ENCOURAGEMENT</div>
                        </div>
                        <div className="perk_content back">
                            We will encourage your college activities via various
                            cross-campus brain-storming and networking sessions
                            through telepresence and hangout sessions.
                        </div>
                    </div>
                    <div className="perk_template">
                        <div className="front">
                            <div className="perk_icon">
                                <img className="perk_icon" src={require("../Assets/Icon/CF.webp")} />
                            </div>
                            <div className="perk_head">CERTIFICATION</div>
                        </div>
                        <div className="perk_content back">
                            The coordinator gets a certificate from E-Cell,
                            BITS Pilani Hyderabad Campus, at the end of this successful
                            collaboration.
                        </div>
                    </div>
                    <div className="perk_template">
                        <div className="front">
                            <div className="perk_icon">
                                <img className="perk_icon" src={require("../Assets/Icon/SM.webp")} />
                            </div>
                            <div className="perk_head">SOCIAL MEDIA</div>
                        </div>
                        <div className="perk_content back">
                            Your college name and social media site links will be
                            displayed on our webpage, thereby increasing popularity.
                        </div>
                    </div>
                    <div className="perk_template">
                        <div className="front">
                            <div className="perk_icon">
                                <img className="perk_icon" src={require("../Assets/Icon/ST.webp")} />
                            </div>
                            <div className="perk_head">STRUCTURING</div>
                        </div>
                        <div className="perk_content back">
                            The structure and development of various activities
                            will be shared, and help will be provided to structure
                            new events and activities.
                        </div>
                    </div>
                </div>
            </div>
            <div className="ec_page3">
                <div className="vision_heading"> SWEEP SWEEPSTAKES</div>
                <video width="80%" autoPlay controls>
                    <source src={require("../Assets/sweep.mp4")} type="video/mp4" />
                </video>
            </div>
            <div style={{height:"194vw"}} className="ec_page2">
                <div style={{ fontSize: "30px" }} className="register_head">
                    REGISTER HERE FOR SWEEPSTAKES
                </div>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf3OsnDnma88WjjWEA_qRJ-1B6AicFagQ-4y0ihEAeDOi6SPw/viewform?embedded=true" width="80%" height="2416" frameborder="0" marginheight="0" marginwidth="0" scrolling="no">Loading…</iframe>
            </div>
            <div className="ec_page3">
                <div className="vision_heading"> CAMPUS AMBASSADOR</div>
                <div className="vision_heading"> PERKS</div>
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    <div className="perk_template">
                        <div className="front">
                            <div className="perk_icon">
                                <img className="perk_icon" src={require("../Assets/Icon/SA.webp")} />
                            </div>
                            <div className="perk_head">PASSES</div>
                        </div>
                        <div className="perk_content back">
                            Get free pass for the Launchpad 2021 - 5th Edition and access to internship drive
                        </div>
                    </div>
                    <div className="perk_template">
                        <div className="front">
                            <div className="perk_icon">
                                <img className="perk_icon" src={require("../Assets/Icon/CF.webp")} />
                            </div>
                            <div className="perk_head">CERTIFICATION</div>
                        </div>
                        <div className="perk_content back">
                            The campus ambassador gets a certificate from E-Cell,
                            BITS Pilani Hyderabad Campus, after the completion of the Summit.
                        </div>
                    </div>
                    <div className="perk_template">
                        <div className="front">
                            <div className="perk_icon">
                                <img className="perk_icon" src={require("../Assets/Icon/SM.webp")} />
                            </div>
                            <div className="perk_head">SOCIAL MEDIA</div>
                        </div>
                        <div className="perk_content back">
                            You would be featured in our social media pages.
                        </div>
                    </div>
                </div>
            </div>
            <div className="ec_page2">
                <div style={{ fontSize: "30px" }} className="register_head">
                    REGISTER HERE FOR CAMPUS AMBASSADOR
                </div>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeJq8q9av_GDicVxPQG0OnedpqEVHyGT7e5lQEGge1mkq9tcg/viewform?embedded=true" width="80%" height="1853" frameborder="0" marginheight="0" marginwidth="0" scrolling="no">Loading…</iframe>
            </div>
            <div style={{ display: "none" }} className="ec_page2">
                <div className="register_head">
                    REGISTER HERE
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ borderRadius: "20px", backgroundColor: "var(--color-2)", display: "flex", flexDirection: "column", padding: "10px 50px 50px 50px" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <input value={name} onChange={e => setName(e.target.value)} className="drive_form" placeholder="Name"></input>
                            <input value={college} onChange={e => setCollege(e.target.value)} className="drive_form" placeholder="College"></input>
                            <input value={email} onChange={e => setEmail(e.target.value)} className="drive_form" placeholder="Email Address"></input>
                            <input value={message} onChange={e => setMessage(e.target.value)} style={{ height: "300px" }} className="drive_form" placeholder="Your Message..."></input>
                            <button disabled onClick={onSubmit} className="network_btn" style={{ alignSelf: "center" }}> COMING SOON </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Econnect