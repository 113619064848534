import React from "react"
import "./Contact_Profiles.css"
import Zoom from "react-reveal/Zoom"

const Contact_Profiles = (props) => {
    return (
        <Zoom>
            <div style={{ display: "flex", flexDirection: "column", margin: "30px" }}>
                <img src={props.pic} className="contact_dp" />
                <div className="contact_name"> {props.name} </div>
                <div className="contact_name"> {props.surname} </div>
                <div className="contact_post"> {props.position} </div>
                <div className="contact_number"> {props.number} </div>
            </div>
        </Zoom>
    )
}

export default Contact_Profiles