import React, { Component } from "react";
// import "./Dashboard.css";
import "./merged.css";
import JobDesc from "./JobDesc";
import Footer from "../Footer/Footer";
import axios from "axios";
import { REQUEST_URL } from "../config";
import firebaseRef from "../firebaseRef";
import Profile1 from "./Components/profile1";
import "./dashboard.css";
import AppliedCompany from "./Components/appliedcompany";
import Dashboardcomplete from "./Components/Dashboardcomplete";

class Dashboard extends Component {
  state = {
    buttonstate: "dashboard",
    jobs: [],
    profile: {},
    appliedCompanies: [],
    identifier: {},
  };

  componentWillMount() {
    axios
      .post(`${REQUEST_URL}/jobs/getAllJobs`, {
        applicantID: firebaseRef.auth().currentUser.uid,
      })
      .then((response) => {
        const jobs = response.data;
        this.setState({
          jobs: jobs,
        });
      });

    axios
      .post(`${REQUEST_URL}/account/getProfile`, {
        userID: firebaseRef.auth().currentUser.uid,
        accountType: 1,
      })
      .then((response) => {
        const profile = response.data;
        this.setState({
          profile: profile,
        });
        console.log(response.data);
      });

    axios
      .post(`${REQUEST_URL}/applications/getStudentApplied`, {
        applicantID: firebaseRef.auth().currentUser.uid,
      })
      .then((response) => {
        const appliedCompanies = response.data;
        this.setState({
          appliedCompanies: appliedCompanies,
        });
        console.log("applied companies", response.data);
      });

    axios
      .post(`${REQUEST_URL}/payments/createIdentifier`, {
        userID: firebaseRef.auth().currentUser.uid,
      })
      .then((response) => {
        const res = response.data;
        this.setState({
          identifier: res,
        });
        console.log(response.data);
      });

    // fetch("http://localhost:8080/jobs/getAllJobs").then((response) => response.json()).then((data) => {
    //         this.setState({
    //             jobs: data
    //         })
    //         console.log(data)
    //         console.log(this.state.jobs)
    //     }).catch((error) => console.log(error))
  }

  render() {
    const onLogout = (event) => {
      firebaseRef.auth().signOut();
    };

    var display = null;
    const buttonclick = (option) => {
      this.setState({ buttonstate: option });
      console.log(this.state.buttonstate);
      switch (option) {
        case "view":
          document.querySelector(".dashboard").classList.remove =
            "button_click";
          document.querySelector(".myprofile").classList.remove =
            "button_click";
          document.querySelector(".Appliedcompanies").classList.remove =
            "button_click";
          document.querySelector(".Viewcompany").classList.add = "button_click";
          document.querySelector(".paynow").classList.remove = "button_click";
      }
    };

    if (this.state.buttonstate === "view") {
      display = (
        <div className="container2">
          {this.state.jobs.length ? (
            <ul>
              {this.state.jobs.map((job) => (
                <li key={job.id}>
                  <JobDesc
                    id={job.id}
                    footnotes="null"
                    companyID={job.companyID}
                    companyName={job.companyName}
                    JobDesc={job.desc}
                    duration=""
                    stipend={""} // job.stipend
                    eligibility={""} // job.preReq
                    logo={require(`../Assets/ID logos/${job.srcUrl}`)}
                    tags={job.domain ? job.domain : []}
                    paid={this.state.profile.paid}
                    resumeRef={this.state.profile.resumeRef}
                  />
                </li>
              ))}
            </ul>
          ) : (
            <div class="loader"></div>
          )}
        </div>
      );
    }
    if (this.state.buttonstate === "profile") {
      display = (
        <div style={{ height: "100%" }}>
          <Profile1 />
        </div>
      );

      //   this.state.profile ? display = <Profile1
      //   name={this.state.profile.name}
      //   email={this.state.profile.email}
      //   bio={this.state.profile.bio}
      //   city={this.state.profile.city}
      //   contact={this.state.profile.contact}
      //   yos={this.state.profile.yos}
      //   college={this.state.profile.college}
      //  /> : display = <div class="loader"></div>
    }
    if (this.state.buttonstate === "pay") {
      if (!this.state.profile.paid) {
        display = (
          <iframe
            src={`https://www.meraevents.com/ticketWidget?eventId=240043&ucode=organizer&wcode=9063CD-9063CD-333333-9063CD-&theme=1&txnID=${this.state.identifier.txnID}&identifer=${this.state.identifier.identifer}`}
            width="100%"
            height="600px"
            frameborder="0px"
          ></iframe>
        );
      } else {
        display = (
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              display: "flex",
              alignContent: "center",
            }}
          >
            {" "}
            Already Paid{" "}
          </div>
        );
      }

      // display = <iframe src="https://www.meraevents.com/ticketWidget?eventId=240043&ucode=organizer&wcode=9063CD-9063CD-333333-9063CD-&theme=1" width="100%" height="600px" frameborder="0px" ></iframe>
    }
    if (this.state.buttonstate === "dashboard") {
      display = (
        <div style={{ height: "100%" }}>
          {this.state.profile ? (
            <Dashboardcomplete profile={this.state.profile} />
          ) : (
            <div class="loader"></div>
          )}
        </div>
      );
    }
    if (this.state.buttonstate === "applied") {
      display = (
        <div style={{ overflow: "auto" }}>
          {this.state.appliedCompanies ? (
            <AppliedCompany companies={this.state.appliedCompanies} />
          ) : (
            <div class="loader"></div>
          )}
        </div>
      );
    }
    return (
      <div>
        <div className="mobile-nav">
          <input type="checkbox" className="nav-checkbox" id="nav-toggle" />
          <label for="nav-toggle" className="nav-button">
            <i className="fa fa-bars"></i>
          </label>
          <div className="nav-background">&nbsp;</div>
          <nav className="nav-contents">
            <div className="nav-contents-wrapper">
              <div className="dashboard">
                <button type="button" className="button buttonac">
                  <i className="fa fa-desktop" style={{ marginRight: 7 }}></i>{" "}
                  Dashboard
                </button>
              </div>
              <div className="myprofile">
                <button type="button" className="button buttonac">
                  <i className="fa fa-user" style={{ marginRight: 7 }}></i> My
                  Profile
                </button>
              </div>
              <div className="myprofile">
                <button type="button" className="button buttonac">
                  <i className="fa fa-briefcase" style={{ marginRight: 7 }}></i>
                  Applied Companies
                </button>
              </div>
              <div className="myprofile">
                <button type="button" className="button buttonac">
                  <i className="fa fa-list" style={{ marginRight: 7 }}></i> View
                  Company List
                </button>
              </div>
              <div className="myprofile">
                <button type="button" className="button buttonac">
                  <i
                    className="fa fa-credit-card"
                    style={{ marginRight: 7 }}
                  ></i>{" "}
                  Pay Now
                </button>
              </div>
            </div>
          </nav>
        </div>
        <div className="id_back" style={{ padding: "100px 0 0 0" }}>
          <div className="maincontainer" style={{ margin: "0 0 50px 0" }}>
            <div className="container1">
              <div className="inner">
                <div className="ecell"> INTERNSHIP DRIVE</div>
                <div className="dashboard sidebar-button">
                  <button
                    onClick={() => {
                      buttonclick("dashboard");
                    }}
                    type="button"
                    className="button"
                  >
                    <i
                      className="fa fa-desktop"
                      style={{ marginRight: "7px" }}
                    ></i>{" "}
                    Dashboard
                  </button>
                </div>
                <div className="myprofile sidebar-button">
                  <button
                    onClick={() => {
                      buttonclick("profile");
                    }}
                    type="button"
                    className="button"
                  >
                    <i
                      className="fa fa-user"
                      style={{ marginRight: "7px" }}
                    ></i>{" "}
                    My Profile
                  </button>
                </div>
                <div className="Appliedcompanies sidebar-button">
                  <button
                    onClick={() => {
                      buttonclick("applied");
                    }}
                    type="button"
                    className="button"
                  >
                    <i
                      className="fa fa-briefcase"
                      style={{ marginRight: "7px" }}
                    ></i>
                    Applied Companies
                  </button>
                </div>
                <div className="Viewcompany sidebar-button">
                  <button
                    onClick={() => {
                      buttonclick("view");
                    }}
                    type="button"
                    className="button"
                  >
                    <i
                      className="fa fa-list"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    View Company List
                  </button>
                </div>
                <div className="paynow sidebar-button">
                  <button
                    onClick={() => {
                      buttonclick("pay");
                    }}
                    type="button"
                    className="button"
                  >
                    <i
                      className="fa fa-credit-card"
                      style={{ marginRight: "7px" }}
                    ></i>{" "}
                    Pay Now
                  </button>
                </div>
                <div className="logOut">
                  <button
                    onClick={() => {
                      onLogout();
                    }}
                    type="button"
                    className="button button_logout"
                  >
                    <i
                      className="fa fa-power-off"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    Log out
                  </button>
                </div>
              </div>
            </div>
            {display}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Dashboard;
