import React from "react"
import Footer from "../Footer/Footer"
import "./Peer.css"

const Peer = () => {
    return (
        <div>
            <img style={{width:"100%"}} src={require("../Assets/Backgrounds/Soon.webp")} />
            <div style={{ display: "none" }}>
                <img src={require("../Assets/Big Launch.gif")} style={{ width: "100%" }} />
            </div>
            <div style={{display:"none", justifyContent:"space-around"}}>
                <div className="what_heading">
                    What is Peer to Peer?
                </div>
                <div className="what_content">
                    An integral part of entrepreneurship is learning. 
                    In this direction, CEL’s peer-to-peer program has been planned 
                    to impart knowledge and insights into start-ups via learning 
                    sessions and discussions held with the members. With carefully 
                    curated sessions with assignments such as case studies, 
                    the main aim is to enrich everyone’s entrepreneurship-related 
                    knowledge.
                </div>
            </div>
            <div style={{display:"none", justifyContent:"space-around"}}>
                <div className="vision_heading">
                    VISION
                </div>
                <div className="vision_content">
                    Preparing a structure of topics to be discussed or samples of 
                    assignments such as case studies following the ECell team members’ 
                    response is not an easy task, but the dedication and commitment 
                    to learning and spreading knowledge make it possible. 
                </div>
            </div>
            <div style={{display:"none"}} className="register_head">
                OUR SESSIONS
            </div>
            <div style={{display:"none", flexDirection:"row", justifyContent:"center", flexWrap:"wrap"}}>
                <div style={{width:"400px", margin:"50px"}}>
                    <a href={require("../Assets/PPTS/VP.pptx")} target="_blank">
                        <img src={require("../Assets/PPTS/VP.webp")} className="thumbnail"/>
                    </a>
                    <div className="session_head"> VALUE PROPOSITION </div>
                </div>
                <div style={{width:"400px", margin:"50px"}}>
                    <a href={require("../Assets/PPTS/RM.pdf")} target="_blank">
                        <img src={require("../Assets/PPTS/RM.webp")} className="thumbnail"/>
                    </a>
                    <div className="session_head"> REVENUE MODELS </div>
                </div>
                <div style={{width:"400px", margin:"50px"}}>
                    <a href="#">
                        <img src={require("../Assets/Contacts/SG.webp")} className="thumbnail"/>
                    </a>
                    <div className="session_head"> GUESSTIMATES </div>
                </div>
                <div style={{width:"400px", margin:"50px"}}>
                    <a href="#">
                        <img src={require("../Assets/Contacts/SG.webp")} className="thumbnail"/>
                    </a>
                    <div className="session_head"> DATA PRIVACY </div>
                </div>
                <div style={{width:"400px", margin:"50px"}}>
                    <a href="#">
                        <img src={require("../Assets/Contacts/SG.webp")} className="thumbnail"/>
                    </a>
                    <div className="session_head"> CUSTOMER DEVELOPMENT </div>
                </div>
                <div style={{width:"400px", margin:"50px"}}>
                    <a href="#">
                        <img src={require("../Assets/Contacts/SG.webp")} className="thumbnail"/>
                    </a>
                    <div className="session_head"> CAST INTERVIEW PREPARATION </div>
                </div>
                <div style={{width:"400px", margin:"50px"}}>
                    <a href={require("../Assets/PPTS/AF.pdf")} target="_blank">
                        <img src={require("../Assets/PPTS/AF.webp")} className="thumbnail"/>
                    </a>
                    <div className="session_head"> AMAZON V/s FLIPKART </div>
                </div>
                <div style={{width:"400px", margin:"50px"}}>
                    <a href="#">
                        <img src={require("../Assets/Contacts/SG.webp")} className="thumbnail"/>
                    </a>
                    <div className="session_head"> FINANCE IN A START-UP AND BEGINNER'S GUIDE TO STOCK MARKET </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Peer;