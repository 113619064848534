import React from "react"
import Footer from "../../Footer/Footer"
import "./quiz.css"
import Slide from "react-reveal/Slide"
const Quiz = () => {

    return (
        <div style={{overflow:"scroll"}}>
            <div className="qz_1">
                    <div style={{ transform: "translate(0%, 50%)" , width:"fit-content"}} class="wrapper">
                        <h1><span class="text_1 heading">ENTREQUIZTA</span></h1>
                        <div className="page1_sub" style={{fontSize:"20px", padding:"5px",color:"white",fontWeight:"900",WebkitTextStrokeColor:"black",WebkitTextStrokeWidth:"1px"}}>
                        Test your entrepreneurship knowledge
                        </div>
                        <button style={{backgroundColor:"var(--color-1)", width:"fit-content", margin:"5px"}} className="id_login">
                            <a style={{textDecoration:"none",  color:"var(--color-2)"}} href="https://www.meraevents.com/event/launchpad-2021-5th-edition?ucode=organizer" target="_blank">
                            VIEW OTHER EVENTS</a></button>
                    </div>
            </div> 
            <div className="fb_live">
                    <div className="live">
                        LIVE STREAM   
                    </div> <br />
                    <div style={{width:"80%" , height:"90%"}}>     
                        <video width="100%" height="90%" controls>
                            <source src={require("../../Assets/sweep.mp4")} type="video/mp4" />
                        </video> 
                    </div>              
            </div>
            <Footer />
        </div>
        )
}

export default Quiz;