import React from "react"
import Footer from "../Footer/Footer"

const Coffee = () => {
    return (
        <div>
            <div style={{ display: "flex" }}>
                <img src={require("../Assets/Coffee with cel.jpg")} style={{ width: "100%" }} />
            </div>
            <div className="register_head">
                COFFEE WITH CEL
            </div>
            <Footer />
        </div>
    )
}

export default Coffee;