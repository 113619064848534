import React from "react"
import Footer from "../Footer/Footer"

const Entrespeak =() =>{
    return (
        <div>
            <div style={{ display: "flex" }}>
                <img src={require("../Assets/Big Launch.gif")} style={{ width: "100%" }} />
            </div>
            <div className="register_head">
                ENTRESPEAKS
            </div>
            <Footer />
        </div>
    )
}

export default Entrespeak;