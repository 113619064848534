import React, {Component} from 'react'
import axios from 'axios'
import { REQUEST_URL } from '../config'
import firebaseRef from '../firebaseRef'
import { Redirect } from 'react-router'

class AuthPayment extends Component{

    state = {
        complete: false
    }

    componentDidMount() {
        var id = new URLSearchParams(this.props.location.search).get("id")
        var txnID = new URLSearchParams(this.props.location.search).get('txnID')
        console.log(txnID)
        console.log(id)

        axios
        .post(`${REQUEST_URL}/jobs/getAllJobs`, {
            userID: firebaseRef.auth().currentUser.uid,
            txnID: txnID,
            identifer: id
        })
        .then((response) => {
            this.setState({
                complete: true
            })
        });

    }

    render() {
        return (
            <div>
                {
                    this.state.complete ? <Redirect to='https://ecellbphc.in/internship-drive/app'/> : <div>Processing</div>
                }
            </div>
        )
    }
}

export default AuthPayment