import firebaseRef from '../firebaseRef';
import { v4 as uuidv4 } from 'uuid';

function submitEConnectForm(name, email, college, message) {
    
    const databseRef = firebaseRef.firestore()
    
    const docID = uuidv4()

    const data = {
        id: docID,
        name: name,
        email: email,
        college: college,
        message: message
    }

    databseRef.collection('econnect-responses').doc(docID).set(data)

}

function submitStudentRegistrationForm(name, email, college, contact, year, query) {
    
    const databseRef = firebaseRef.firestore()
    
    const docID = uuidv4()

    const data = {
        id: docID,
        name: name,
        email: email,
        college: college,
        contact: contact,
        year: year,
        query: query
    }

    databseRef.collection('studentreg-responses').doc(docID).set(data)

}

function submitStartupRegistrationForm(execName, name, emp, email, contact, query) {
    
    const databseRef = firebaseRef.firestore()
    
    const docID = uuidv4()

    const data = {
        id: docID,
        execName: execName,
        name: name,
        employees: emp,
        email: email,
        contact: contact,
        query: query
    }

    databseRef.collection('startupreg-responses').doc(docID).set(data)

}

function submitBPStudentForm(name, college, email, contact, year, message) {
    
    const databseRef = firebaseRef.firestore()
    
    const docID = uuidv4()

    const data = {
        id: docID,
        name: name,
        college: college,
        email: email,
        contact: contact,
        year: year,
        message: message
    }

    databseRef.collection('bp-student-responses').doc(docID).set(data)
    console.log("ref:done")
}

function submitBPStartupForm(execName, name, emp, email, contact, message) {
    
    const databseRef = firebaseRef.firestore()
    
    const docID = uuidv4()

    const data = {
        id: docID,
        execName: execName,
        name: name,
        emp: emp,
        email: email,
        contact: contact,
        message: message
    }

    databseRef.collection('bp-startup-responses').doc(docID).set(data)

}

function submitGRForm(execName, email, contact, college, city, name, sector, brief, namesMembers, whyYou, campaign) {
    
    const databseRef = firebaseRef.firestore()
    
    const docID = uuidv4()

    const data = {
        id: docID,
        execName: execName,
        email: email,
        contact: contact,
        college: college,
        city: city,
        name: name,
        sector: sector,
        brief: brief, 
        namesMembers: namesMembers,
        whyYou: whyYou,
        campaign: campaign
    }

    databseRef.collection('gr-startup-responses').doc(docID).set(data)

}

function submitBPForm(execName, email, contact, college, city, name, sector, brief, namesMembers, whyYou, campaign) {
    
    const databseRef = firebaseRef.firestore()
    
    const docID = uuidv4()

    const data = {
        id: docID,
        execName: execName,
        email: email,
        contact: contact,
        college: college,
        city: city,
        name: name,
        sector: sector,
        brief: brief, 
        namesMembers: namesMembers,
        whyYou: whyYou,
        campaign: campaign
    }

    databseRef.collection('bp-startup-responses').doc(docID).set(data)

}

export {
    submitEConnectForm,
    submitStudentRegistrationForm,
    submitStartupRegistrationForm,
    submitBPStudentForm,
    submitBPStartupForm,
    submitGRForm,
    submitBPForm
}