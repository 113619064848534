import React from "react";
import "./Contact.css";
import Contact_Profiles from "./Contact_Profiles";
import Names from "../Storage/Contacts.json";
import Footer from "../Footer/Footer";

const Contact = () => {
  return (
    <div className="contact">
      <div>
        <img
          src={require("../Assets/Contacts.webp")}
          style={{ width: "100%" , borderRadius:"50%"}}
        />
      </div>
      <div style={{ display: "none" }} className="ct_page1">
        <img
          src={require("../Assets/Backgrounds/Gopi.webp")}
          className="head_image"
        />
        <img
          src={require("../Assets/Backgrounds/Rakesh.webp")}
          className="head_image"
        />
        <img
          src={require("../Assets/Backgrounds/Ratnesh.webp")}
          className="head_image"
        />
      </div>
      <div
        style={{ display: "none", flexWrap: "wrap", justifyContent: "center" }}
      >
        {Names.map((Mapping) => {
          return (
            <Contact_Profiles
              key={Mapping.name}
              name={Mapping.name}
              surname={Mapping.surname}
              pic={require("../Assets/Contacts" + Mapping.pic)}
              position={Mapping.position}
              number={Mapping.number}
            />
          );
        })}
      </div>
      <div
        style={{
          display: "none",
          justifyContent: "center",
          backgroundColor: "var(--color-5)",
          borderBottom: "1px solid var(--color-1)",
        }}
      >
        <div className="contact_form">
          <div className="contactus_heading">Contact Us</div>
          <input className="drive_form" placeholder="Name"></input>
          <input className="drive_form" placeholder="College"></input>
          <input className="drive_form" placeholder="Email Address"></input>
          <button className="network_btn" style={{ alignSelf: "center" }}>
            {" "}
            CONTACT US{" "}
          </button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          backgroundColor: "var(--color-5)",
          paddingTop: "50px",
          paddingBottom: "100px",
        }}
      >
        <div className="contact-card">
          <button className="contact_icon">
            <div>
              <i className="fa fa-map-signs"></i>
            </div>
          </button>
          <div className="contact_heading"> Address</div>
          <div style={{ fontWeight: "500", textAlign: "center" }}>
            {" "}
            BITS Pilani Hyderabad Campus, Hyderabad 500078{" "}
          </div>
        </div>
        <div className="contact-card">
          <button className="contact_icon">
            <i className="fa fa-phone"></i>
          </button>
          <div className="contact_heading"> Contact Number</div>
          <div style={{ fontWeight: "500", textAlign: "center" }}>
            {" "}
            +91-7589268096{" "}
          </div>
        </div>
        <div className="contact-card">
          <button className="contact_icon">
            <i className="fa fa-paper-plane"></i>
          </button>
          <div className="contact_heading"> Email Address</div>
          <div style={{ fontWeight: "500", textAlign: "center" }}>
            {" "}
            ecell@hyderabad.bits-pilani.ac.in{" "}
          </div>
        </div>
        <div className="contact-card">
          <button className="contact_icon">
            <i className="fa fa-globe"></i>
          </button>
          <div className="contact_heading"> Website</div>
          <div style={{ fontWeight: "500", textAlign: "center" }}>
            {" "}
            ecellbphc.in{" "}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
