import React from "react"
import "./Events.css"
import {Link} from "react-router-dom"

const Events = (props) => {
    return (
        <div className="boxes">
            <Link to={`/${props.link}`}>
                <img src={props.pic} className="launch_events e-connect"/>
            </Link>
            <div className="levents_heading">{props.name}</div>
        </div>
    )
}

export default Events;