import Axios from 'axios'
import React from 'react'
import "./dashboard.css"
import axios from 'axios'
import { REQUEST_URL } from '../config'
import { Link } from 'react-router-dom'
import firebaseRef from '../firebaseRef'

//import REQUEST_URL from './../config'

function JobDesc(props) {

    var jobID = props.id
    var companyID = props.companyID
    var footnotes = props.footnotes
    var paid = props.paid
    var resumeRef = props.resumeRef
    console.log(resumeRef)

    const [response, setResponse] = React.useState(0)

    function CTAButton(props) {
        switch (response) {
            case 0:
                return <button onClick={apply} className="cart">APPLY NOW</button>
            case 1:
                return <div style={{backgroundColor:"transparent", color: 'white'}}>Processing</div>
            case 2:
                return <div style={{backgroundColor:"transparent", color: 'white'}}>DONE!!</div>
            case 3:
                return <div style={{backgroundColor:"transparent", color: 'white'}}>Already Applied</div>
            case 4:
                return <div style={{backgroundColor:"transparent", color: 'white'}}>Application limit exhausted</div>
            case 5:
                return <div style={{backgroundColor:"transparent", color: 'white'}}>Upload resume first</div>
            case 6:
                return <div style={{backgroundColor:"transparent", color: 'white'}}>You need a ticket to apply</div>
            default:
                return <button onClick={apply} className="cart">APPLY NOW</button>
        }
    }

    return (
        <div className='container-company'>
                        <div className="picture">
                            <img src={props.logo} alt="" style={{ margin: "10px", height: "auto", paddingBottom: "20px" }} />
                            <div className="tag-container">
                                {props.tags.map((value, index) =>{ return(
                                    <div key={index} className="tag">
                                        {value}
                                    </div>
                                    );}
                            
                                )}
                            </div>
                        </div>
                        <div className="andarka">
                            <div className="head">{props.companyName}</div>
                            <div className="subhead">{props.jobDesc}</div>
                            <div className="table">
                                    <a style={{color: 'white', textDecorationLine: 'underline'}} target="_blank" rel="noopener noreferrer" href={props.JobDesc}>Job Description</a>
                            </div>
                        </div>
                        <div className="pricecontainer">
                            <CTAButton></CTAButton>
                        </div>
        </div>
    )

    async function apply(event) {
        event.preventDefault()

        setResponse(1)

        // console.log(firebaseRef.auth().currentUser.uid)
        // console.log(companyID)
        // console.log(footnotes)
        // console.log(jobID)
        // console.log('appliedstate', resumeRef)
        //${REQUEST_URL}
        if (resumeRef && resumeRef !== 'null') {
            if (paid) {
                //console.log('reached')
                await axios.post(`${REQUEST_URL}/applications/createApplication`, {
                    applicantID: firebaseRef.auth().currentUser.uid,
                    companyID: companyID,
                    footnotes: footnotes,
                    jobID: jobID
                }).then((response) => {
                    //console.log(response)
                    if (response.status === 200 && response.data === 'done') {
                        setResponse(2)
                    } else if (response.status === 200 && response.data === 'Already applied') {
                        setResponse(3)
                    }
                    
                }).catch((err) => {
                    //console.log(err)
                    if (err.data === 'Application limit exhausted.'){
                        setResponse(4)
                    }
                    setResponse(4)
                    // console.log(err)
                })
            } else {
                setResponse(6)
            }
        } else {
            setResponse(5)
        }

        // var response = await fetch('http://localhost:8080/applications/createApplication', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         applicantID: "edjsad",
        //         companyID: companyID,
        //         footnotes: "jdsn",
        //         jobID: jobID
        //     })
        // })

        // if (response.code === 400) {
        //     setResponse(0)
        // }

        // console.log(response)
    }

}

export default JobDesc
