import React from "react";
import "./Internship Drive.css";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";

import {
  submitStartupRegistrationForm,
  submitStudentRegistrationForm,
} from "../services/formResponse";

const Drive = () => {
  // student states
  const [studentName, setStudentName] = React.useState("");
  const [studentCollege, setStudentCollege] = React.useState("");
  const [studentEmail, setStudentEmail] = React.useState("");
  const [studentContact, setStudentContact] = React.useState("");
  const [studentYear, setStudentYear] = React.useState("");
  const [studentMessage, setStudentMessage] = React.useState("");

  // startup states
  const [startupExecName, setStartupExecName] = React.useState("");
  const [startupName, setStartupName] = React.useState("");
  const [startupEmp, setStartupEmp] = React.useState("");
  const [startupEmail, setStartupEmail] = React.useState("");
  const [startupContact, setStartupContact] = React.useState("");
  const [startupMessage, setStartupMessage] = React.useState("");

  function onStudentSubmit(event) {
    event.preventdefault();
    submitStudentRegistrationForm(
      studentName,
      studentEmail,
      studentCollege,
      studentContact,
      studentYear,
      studentMessage
    );
  }

  function onStartupSubmit(event) {
    event.preventdefault();
    submitStartupRegistrationForm(
      startupExecName,
      startupName,
      startupEmp,
      startupEmail,
      startupContact,
      startupMessage
    );
  }

  return (
    <div>
      <div className="id_page1">
        <div class="wrapper">
          <h1>
            <span class="text_1">INTERNSHIP DRIVE</span>
          </h1>
          <div className="page1_sub">Find the perfect start to your career</div>
          <a style={{textDecoration:"none"}} href="/internship-drive/app">
          <button className="id_login register-button">
            REGISTER
          </button>
          </a>
        </div>
      </div>
      <div>
        <div style={{ display: "none", justifyContent: "space-around" }}>
          <div className="what_heading">What is Internship Drive?</div>
          <div className="what_content">
            Internship Drive held as a part of Launchpad every year aims to
            ensure that every deserving student can get a hands-on industrial
            experience. With several startups every year and the number of
            applicants on the rise, this is a fantastic opportunity for those
            who wish to seek some real-life experience in the corporate.
          </div>
        </div>
        <div style={{ display: "none", justifyContent: "space-around" }}>
          <div className="vision_heading">VISION</div>
          <div className="vision_content">
            The vision of Internship Drive is to provide opportunities for
            everyone. An internship allows you to grow your career and skills
            and is sure to set your resume apart. Working as a part of a startup
            is an enriching experience and leaves you with several lessons for
            life, including better decision-making skills and logical abilities.
          </div>
        </div>
      </div>
      <div className="id_page4">
                <video width="100%" controls>
                    <source src={require("../Assets/ID.mp4")} type="video/mp4" />
                </video>
            </div>
      <div className="id_page2">
        <div className="stat_block">
          <div className="stat_number">50+</div>
          <div className="stat_head">START UPS</div>
        </div>
        <div className="stat_block">
          <div className="stat_number">30K+</div>
          <div className="stat_head">STIPEND</div>
        </div>
        <div className="stat_block">
          <div className="stat_number">100+</div>
          <div className="stat_head">CONVERSIONS</div>
        </div>
        <div className="stat_block">
          <div className="stat_number">3000+</div>
          <div className="stat_head">APPLICATIONS</div>
        </div>
      </div>
      <div className="id_page3">
        <div style={{ fontSize: "35px" }} className="register_head">
          SOME COMPANIES THIS YEAR
        </div>
        <div style={{display:"flex", justifyContent:"center", flexWrap:"wrap", width:"80%", marginBottom:"30px"}}>
            <div className="company_plate">
                <div className="com_name">Jally Engineering</div>
                <img src={require("../Assets/ID logos/Jally.png")}
                style={{ margin: "10px", width: "250px", height: "auto" }} />
            </div>
            <div className="company_plate">
                <div className="com_name">Zhiffy</div>
                <img src={require("../Assets/ID logos/Zhiffy.png")}
                style={{ margin: "10px", width: "250px", height: "auto" }} />
              </div>
              <div className="company_plate">
                <div className="com_name">ABL Workspaces</div>
                <img src={require("../Assets/ID logos/ABL Workspaces.webp")}
                style={{ margin: "10px", width: "250px", height: "auto" }} />
            </div>
            <div className="company_plate">
                <div className="com_name">VOLUNTHERE</div>
                <img src={require("../Assets/ID logos/volunt.png")}
                style={{ margin: "10px", width: "250px", height: "auto" }} />
              </div>
            <div className="company_plate">
                <div className="com_name">Fern Partners</div>
                <img src={require("../Assets/ID logos/Fern Partners.webp")}
                style={{ margin: "10px", width: "250px", height: "auto" }} />
            </div>
            <div className="company_plate">
                <div className="com_name">Yantro Mitra</div>
                <img src={require("../Assets/ID logos/YantroMitra.webp")}
                style={{ margin: "10px", width: "250px", height: "auto" }} />
            </div>
        </div>
      </div>
      <div style={{ display: "none" }} className="id_page3">
        <div className="register_head">REGISTER HERE</div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              borderRadius: "20px",
              backgroundColor: "var(--color-2)",
              display: "flex",
              flexDirection: "column",
              padding: "10px 50px 50px 50px",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ margin: "15px" }} className="">
                Student
              </div>
              <div style={{ margin: "15px" }} className="">
                Start-Up
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <input
                value={studentName}
                onChange={(e) => setStudentName(e.target.value)}
                required
                className="drive_form"
                placeholder="Name"
              ></input>
              <input
                value={studentCollege}
                onChange={(e) => setStudentCollege(e.target.value)}
                required
                className="drive_form"
                placeholder="College"
              ></input>
              <input
                value={studentEmail}
                onChange={(e) => setStudentEmail(e.target.value)}
                required
                className="drive_form"
                placeholder="Email Address"
              ></input>
              <input
                value={studentContact}
                onChange={(e) => setStudentContact(e.target.value)}
                required
                className="drive_form"
                placeholder="Contact Number"
              ></input>
              <input
                value={studentYear}
                onChange={(e) => setStudentYear(e.target.value)}
                required
                className="drive_form"
                placeholder="Year of Study"
              ></input>
              <input
                value={studentMessage}
                onChange={(e) => setStudentMessage(e.target.value)}
                required
                className="drive_form"
                placeholder="Queries"
              ></input>
              <button
                onClick={onStudentSubmit}
                className="network_btn"
                style={{ alignSelf: "center" }}
              >
                {" "}
                COMING SOON{" "}
              </button>
            </div>
            <div style={{ display: "none", flexDirection: "column" }}>
              <input
                value={startupExecName}
                onChange={(e) => setStartupExecName(e.target.value)}
                className="drive_form"
                placeholder="Name"
              ></input>
              <input
                value={startupName}
                onChange={(e) => setStartupName(e.target.value)}
                className="drive_form"
                placeholder="Start Up Name"
              ></input>
              <input
                value={startupEmp}
                onChange={(e) => setStartupEmp(e.target.value)}
                className="drive_form"
                placeholder="Number of Employees"
              ></input>
              <input
                value={startupEmail}
                onChange={(e) => setStartupEmail(e.target.value)}
                className="drive_form"
                placeholder="Email Address"
              ></input>
              <input
                value={startupContact}
                onChange={(e) => setStartupContact(e.target.value)}
                className="drive_form"
                placeholder="Contact Number"
              ></input>
              <input
                value={startupMessage}
                onChange={(e) => setStartupMessage(e.target.value)}
                className="drive_form"
                placeholder="Queries"
              ></input>
              <button
                onClick={onStartupSubmit}
                className="network_btn"
                style={{ alignSelf: "center" }}
              >
                {" "}
                REGISTER{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Drive;
