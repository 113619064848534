import React from "react"
import { Component } from "react";
import "./GR.css"
import Footer from "../../Footer/Footer"
import Form from 'react-bootstrap/Form'
import jquery from 'jquery'

import { submitGRForm } from '../../services/formResponse'

class GR extends Component {

    constructor() {
        super();
        this.myRef = React.createRef();
    }

    state = {
        progress: 4,
        index: 0,
        execName: '',
        name: '',
        email: '',
        contact: '',
        college: '',
        city: '',
        sector: '',
        brief: '',
        teamMates: '',
        whyYou: '',
        campaign: ''
    }
    componentDidMount() {
        jquery('.faq-q').click(function(){
            if (jquery(this).siblings().find('.faq-a').is(':visible')) {
                jquery(this).removeClass('faq-q-open');
                jquery(this).siblings().find('.faq-a').removeClass('faq-a-open').slideUp();
        } 
        else {
            jquery(this).addClass('faq-q-open');
            jquery(this).siblings().find('.faq-a').addClass('faq-a-open').slideDown();
            }
        })
    }
    render() {

        console.log(this.state)

        var display = null;
        const next = () => {
            if (this.state.progress !== 4)
                this.setState({ progress: this.state.progress + 1 })
            console.log(this.state.progress)
        }

        const previous = () => {
            if (this.state.progress !== 0)
                this.setState({ progress: this.state.progress - 1 })
            console.log(this.state.progress)
        }

        const submitForm = () => {
            submitGRForm(this.state.execName, this.state.email, this.state.contact, this.state.college, this.state.city, this.state.name, this.state.sector, this.state.brief, this.state.teamMates, this.state.whyYou, this.state.campaign)
            if (this.state.progress !== 4)
                this.setState({ progress: this.state.progress + 1 })
            console.log(this.state.progress)
        }

        if (this.state.progress === 0) {
            display = <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "30px" }}>
                <Form>
                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Control className="inputfield" value={this.state.execName} onChange={e => {
                            this.setState({
                                execName: e.target.value
                            })
                        }} placeholder="Name" />
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Control className="inputfield" value={this.state.email} onChange={e => {
                            this.setState({
                                email: e.target.value
                            })
                        }} type="email" placeholder="Email" />
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Control className="inputfield" value={this.state.contact} onChange={e => {
                            this.setState({
                                contact: e.target.value
                            })
                        }} placeholder="Contact" />
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Control className="inputfield" value={this.state.college} onChange={e => {
                            this.setState({
                                college: e.target.value
                            })
                        }} placeholder="University/College/Organisation" />
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Control className="inputfield" value={this.state.city} onChange={e => {
                            this.setState({
                                city: e.target.value
                            })
                        }} placeholder="City" />
                    </Form.Group>
                </Form>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <button className="book_next" onClick={next}>Next</button>
                </div>
            </div>
        }
        if (this.state.progress === 1) {
            display = <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control className="inputfield" value={this.state.name} onChange={e => {
                        this.setState({
                            name: e.target.value
                        })
                    }} placeholder="Name of your startup" />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Control className="inputfield" value={this.state.sector} onChange={e => {
                        this.setState({
                            sector: e.target.value
                        })
                    }} as="select">
                        <option>Agriculture</option>
                        <option>Education</option>
                        <option>Energy</option>
                        <option>Entertainment</option>
                        <option>Health</option>
                        <option>Other</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Control className="inputfield" value={this.state.brief} onChange={e => {
                        this.setState({
                            brief: e.target.value
                        })
                    }} as="textarea" placeholder="Brief description of your startup" rows={6} />
                </Form.Group>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <button className="book_next" onClick={previous}>Previous</button>
                    <button className="book_next" onClick={next}>Next</button>
                </div>
            </div>
        }
        if (this.state.progress === 2) {
            display = <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center" }}>
                <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control className="inputfield" value={this.state.teamMates} onChange={e => {
                        this.setState({
                            teamMates: e.target.value
                        })
                    }} placeholder="Names of Team mates, comma separated" />
                </Form.Group>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <button className="book_next" onClick={previous}>Previous</button>
                    <button className="book_next" onClick={next}>Next</button>
                </div>
            </div>
        }
        if (this.state.progress === 3) {
            display = <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>

                <Form>
                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Control className="inputfield" value={this.state.whyYou} onChange={e => {
                            this.setState({
                                whyYou: e.target.value
                            })
                        }} placeholder="Why should you be chosen" />
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Control className="inputfield" value={this.state.campaign} onChange={e => {
                            this.setState({
                                campaign: e.target.value
                            })
                        }} as="select">
                            <option>LinkedIn</option>
                            <option>Facebook</option>
                            <option>Instagram</option>
                            <option>Dare2Complete</option>
                            <option>Friends/Colleauges</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <button className="book_next" onClick={previous}>Previous</button>
                    <button className="book_next" onClick={submitForm}>Submit</button>
                </div>
            </div>
        }
        if (this.state.progress === 4) {
            display = <div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", fontWeight: "600", color: "white", fontFamily: "Poppins, san serif"}} className="reg-closed">
                    The registrations for the competition is over.
                    <div style={{ fontSize: "30px", textAlign:"center" }}>See you in the next Edition </div>
                </div>
            </div>

        }

        return (
            <div>
                <div style={{ display: "flex" }} className="gr_page1">
                    <div style={{ color: "var(--color-1)" }} className="wrapper">
                        <h1><span className="text_1">GROUND REALITY</span></h1>
                        <div className="page1_sub">
                            Ideas won't become reality, until it is tested
                        </div>
                    </div>
                </div>
                <div className="gr_page4">
                    <div style={{ display: "flex", justifyContent: "center", color: "white", flexDirection: "column" }}>
                        <div className="what_heading">What is Ground Reality?</div>
                        <div className="what_content" style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                            Beyond Profits is the Annual Social Business Plan competition organized by E-Cell, BITS Pilani Hyderabad Campus.
                            We understand the meaning of 'giving back to the society and therefore this event gives a platform to the
                            entrepreneurs who are making lives better with their social ventures. This event also helps in encouraging students
                            to pursue social entrepreneurship and bring about innovation in solving social problems. We are confident that
                            the youth today can step up to be the next generation of social revolutions.
                    </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <div className="vision_heading">
                            Recent Achievement
                    </div>
                        <div className="vision_content">
                            With registrations of more than 200 startups from across the nation and prize money of more than Rs. 2,00,000/- was
                            awarded last year making it a sought-after event in Telangana for social startups. It is among very few social
                            competitions among colleges in India. We help the winners by also providing mentorship, legal services, web
                            development and incubation space.
                    </div>
                    </div>
                </div>
                <div className="gr_page3">
                    <div className="gr-info">
                        <div className="gr_head">ELIGIBILITY</div>
                        <div>1. Start-ups that have been incorporated more than four years ago are not eligible to participate in GROUND REALITY.
                        You will be required to submit proof of the same during the competition process.
                            </div>
                        <div>
                            2. Individual participation is allowed in the competition,
                            although participation in teams is highly encouraged. A team can have up to 8 members.
                            </div>
                        <div>
                            3. There is no restriction on incubated startups to participate in the competition.
                            </div>
                        <div>
                            4. Ventures cannot be a buyout, an expansion of an existing company,
                            a real estate syndication, a tax shelter, a franchise, a licensing
                            agreement for distribution in a different geographical area, or a spin-out from an existing corporation
                            </div>
                        <div>
                            5. Teams may be asked to provide proof of the above at any time
                            during the competition cycle and in the eventuality of wrong details/information your entry
                            shall be disqualified.
                            </div>
                    </div>
                    <div className="gr-info">
                        <div className="gr_head">RULES</div>
                        <div>1. Participants are required to register and submit their business models online.
                            </div>
                        <div>
                            2. There is no restriction on any individual to participate. Anyone, from students to working professionals across the world can participate.
                            </div>
                        <div>
                            3. Participation can be done on an individual basis or in a team of up to 8 members.
                            </div>
                        <div>
                            4. There is no restriction on teams taking part in other business plans/ model competitions
                            </div>
                        <div>
                            5. Team Name cannot be changed over the course of the competition. It should be used for any future correspondence or queries.
                            </div>
                        <div>
                            6. A team can submit more than one business model/
                            idea after registration but in doing so, it must register
                            separately for each submission. The same email-id and/or team name cannot be used for doing so.
                            </div>
                        <div>
                            7. A team cannot participate in both GROUND REALITY and BEYOND PROFITS.
                            </div>
                        <div>
                            8. The final authority regarding any decision rests in the hands of organizers.
                            </div>
                        <div>
                            9. The decision of the jury shall be binding and final on the participants
                            </div>
                    </div>
                </div>
                <div className="gr_page5">

                    <div style={{ color: "white" }} className="reg_head">GROUND REALITY REGISTERATION</div>
                    <div style={{ display: "none", justifyContent: "center", marginBottom: "175px" }}>
                        <div id="1" className="dots">1
                        <div className="section_head">Team Leader Details</div></div>
                        <div id="2" className="line"></div>
                        <div id="3" className="dots" style={{ backgroundColor: "var(--color-3)" }}>2
                        <div className="section_head">Startup Details</div></div>
                        <div id="4" className="line" style={{ backgroundColor: "var(--color-3)" }}></div>
                        <div id="5" className="dots" style={{ backgroundColor: "var(--color-3)" }}>3
                        <div className="section_head">Team Member Details</div></div>
                        <div id="6" className="line" style={{ backgroundColor: "var(--color-3)" }}></div>
                        <div id="7" className="dots" style={{ backgroundColor: "var(--color-3)" }}>4
                        <div className="section_head">Finish Registration</div></div>
                    </div>
                    {display}
                    <div className="gr_page5">
                        <div className="reg_head" style={{color:"white"}}>FAQs</div>
                        <div class="schema-faq-code" itemscope="" itemtype="https://schema.org/FAQPage">
                            <div itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question" class="faq-question">
                                <h3 itemprop="name" class="faq-q">What is Launchpad?</h3>
                                <div itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <p itemprop="text" class="faq-a">Launchpad is the Annual Entrepreneurship Summit organized by the Entrepreneurship Cell, BITS Pilani Hyderabad Campus. A grand confluence of keynote speakers, founders, entrepreneurship enthusiasts, investors, VCs, and influencers to expose multiple elements of the start-up ecosystem to the attendees will provide an excellent platform for learning and growing while networking with a stimulating start-up community.
</p>
                                </div>
                            </div>

                            <div itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question" class="faq-question">
                                <h3 itemprop="name" class="faq-q">What is a Business plan competition?</h3>
                                <div itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <p itemprop="text" class="faq-a">A business plan is a road map that provides directions so a business can plan its future and helps in avoiding hurdles on the road to success. Business plan competition is an opportunity for startups across the country to showcase their ideas to an esteemed panel of judges and investors.
</p>
                                </div>
                            </div>

                            <div itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question" class="faq-question">
                                <h3 itemprop="name" class="faq-q"> What is the difference between Ground Reality and Beyond Profits?</h3>
                                <div itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <p itemprop="text" class="faq-a">Ground Reality is our Annual B-plan competition aimed at promoting innovative start-ups in every domain. Beyond Profits is our annual social B-plan competition aimed at promoting social entrepreneurship mainly. While Beyond Profits primarily focuses on startups for the non-profit generation agenda, Ground Round is for a general category.</p>
                                </div>
                            </div>

                            <div itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question" class="faq-question">
                                <h3 itemprop="name" class="faq-q">Can I participate in both competitions?</h3>
                                <div itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <p itemprop="text" class="faq-a">No, you cannot participate in both Ground Reality and Beyond Profits.</p>
                                </div>
                            </div>

                            <div itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question" class="faq-question">
                                <h3 itemprop="name" class="faq-q">How can I register for the competitions?</h3>
                                <div itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <p itemprop="text" class="faq-a">You can register free of cost on our website.</p>
                                </div>
                            </div>

                            <div itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question" class="faq-question">
                                <h3 itemprop="name" class="faq-q">When is the last date for registration?</h3>
                                <div itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <p itemprop="text" class="faq-a">The last date for registration of both the competitions is 20th February 2021.</p>
                                </div>
                            </div>

                            <div itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question" class="faq-question">
                                <h3 itemprop="name" class="faq-q">Will we be provided with mentors?</h3>
                                <div itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <p itemprop="text" class="faq-a">Teams who make it past the first round will be provided with mentor-cum-judges who will guide you in developing your startup.</p>
                                </div>
                            </div>

                            <div itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question" class="faq-question">
                                <h3 itemprop="name" class="faq-q">What are the chances of getting funded by investors?</h3>
                                <div itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <p itemprop="text" class="faq-a">E-Summit being the E-Cell’s flagship event is held annually to bring together the academic community, venture capitalists, new-age entrepreneurs, and all those passionate about entrepreneurship. Hence we have a plethora of opportunities to kick start your startup and all your dreams.
</p>
                                </div>
                            </div>

                            <div itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question" class="faq-question">
                                <h3 itemprop="name" class="faq-q">Are there any perks for the finalists?</h3>
                                <div itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <p itemprop="text" class="faq-a">The finalists of both the competitions will get a chance to witness the talks by some of the greatest minds in the field of entrepreneurship and innovation.</p>
                                </div>
                            </div>

                            <div itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question" class="faq-question">
                                <h3 itemprop="name" class="faq-q">What are the prizes for the winners of Ground Reality and Beyond Profits?</h3>
                                <div itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <p itemprop="text" class="faq-a">The winners get a chance to pitch their ideas to renowned venture capitalists and angel investors. With more than 400 teams and 20 mentors, Ground Reality offers prizes worth more than INR 2,50,000/-and Beyond Profits offers prize money of more than Rs. 1,50,000/-  to participants for accelerating their ideas towards execution. The events also provide winners with legal services, mentorship vouchers, cloud space, acceleration program, and incubation space.</p>
                                </div>
                            </div>

                            <div itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question" class="faq-question">
                                <h3 itemprop="name" class="faq-q">Where can I get to know more about the competitions?</h3>
                                <div itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <p itemprop="text" class="faq-a">You can get more information on all our events on the website. You can also follow our social media pages for regular updates.
</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginBottom: "50px" }}></div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default GR;
