import React, { Component } from "react"
import "./Launchpad.css"
import Events from "./Events/Events"
import L_Events from "../Storage/Home_Events.json"
import Guests from "../Storage/Guests.json"
import GuestPersons from "./Events/GuestPersons"
import Speakers from "./Sub Pages/Speakers"
import Footer from "../Footer/Footer"
import Sponsor from "../Storage/Sponsors.json"
import Zoom from "react-reveal/Zoom"
import Slide from "react-reveal/Slide"

class Launchpad extends Component {
    state = {
        index: 0,
        length: 9
    }
    render() {
        const forward = () => {
            this.setState({ index: this.state.index + 1 })
        }
        const reverse = () => {
            this.setState({ index: this.state.index - 1 })
        }
        return (
            <div>
                <div className="lp_page1">
                    <div class="wrapper">
                        <h1>
                            <span class="text_1">LAUNCHPAD</span>
                        </h1>
                        <div className="page1_sub">Ready for Lift-Off?</div>
                        <a  style={{ textDecoration: "none", color: "var(--color-2)" }} href="https://www.meraevents.com/event/launchpad-2021-5th-edition?ucode=organizer" target="_blank">
                            <button className="id_login register-button">
                                BUY TICKETS
                            </button>
                        </a>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-around", flexDirection: "column" }}>
                    <div style={{ display: "none", flexDirection: "column" }}>
                        <div className="vision_heading">
                            ABOUT US
                        </div>
                        <div style={{ width: "80%" }} className="vision_content">
                            Our annual E-Summit is a majestic confluence of keynote
                            speakers, founders, entrepreneurship enthusiasts, investors,
                            VCs, influencers to introduce the multidimensional
                            elements of the start-up ecosystem to the attendees.
                            This provides an excellent platform to learn
                            from great minds who have dared to go ahead of the ordinary
                            into the extraordinary and grow while networking with
                            a stimulating start-up community
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", alignSelf: "center", marginRight: "100px" }}>
                        <div>
                        </div>

                    </div>
                </div>
                <div className="lp_page2">
                    <div style={{ textAlign: "center" }} className="vision_heading">
                        HIGHLIGHTER EVENTS
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                        <Slide left>
                            {L_Events.map((Mapping) => {
                                return (
                                    <Events
                                        key={Mapping.name}
                                        name={Mapping.name}
                                        link={Mapping.link}
                                        pic={require("../Assets/Launchpad Events" + Mapping.pic)}
                                    />
                                )
                            })}
                        </Slide>
                    </div>
                </div>

                <div style={{ backgroundAttachment: "fixed" }}>
                    <Slide left>
                        <img style={{ width: "100%" }} src={require("../Assets/Backgrounds/S1.webp")} />
                    </Slide>
                    <Slide right>
                        <img style={{ width: "100%" }} src={require("../Assets/Backgrounds/S2.webp")} />
                    </Slide>
                    <Slide left>
                        <img style={{ width: "100%" }} src={require("../Assets/Backgrounds/S3.webp")} />
                    </Slide>
                    <Slide right>
                        <img style={{ width: "100%" }} src={require("../Assets/Backgrounds/S4.webp")} />
                    </Slide>
                    <Slide left>
                        <img style={{ width: "100%" }} src={require("../Assets/Backgrounds/S5.webp")} />
                    </Slide>
                    <Slide right>
                        <img style={{ width: "100%" }} src={require("../Assets/Backgrounds/S6.webp")} />
                    </Slide>
                    <Slide left>
                        <img style={{ width: "100%" }} src={require("../Assets/Backgrounds/S7.webp")} />
                    </Slide>
                    <Slide right>
                        <img style={{ width: "100%" }} src={require("../Assets/Backgrounds/S8.webp")} />
                    </Slide>
                    <Slide left>
                        <img style={{ width: "100%" }} src={require("../Assets/Backgrounds/S9.webp")} />
                    </Slide>
                </div>
                <div style={{ display: "none" }}>
                    <div className="home_headings">
                        OUR PREVIOUS SPONSORS
                    </div>
                    <Zoom>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div id="1" className="sponsor_options sponsor_active">OUR SPONSORS FOR 2020</div>
                            <div id="2" className="sponsor_options"></div>
                        </div>
                    </Zoom>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Zoom>
                            <div style={{ margin: "20px", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                <div className="sponsor_head">POWERED BY</div>
                                <div className="sponsor_image"><img className="sponsor_image" src={require("../Assets/Sponsors/TT.webp")} /></div>
                                <div className="sponsor_name">Telanghana Today</div>
                            </div>
                        </Zoom>
                        <Zoom>
                            <div style={{ margin: "20px", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                <div className="sponsor_head">TITLE PARTNER</div>
                                <div className="sponsor_image"><img className="sponsor_image" src={require("../Assets/Sponsors/TBI.webp")} /></div>
                                <div className="sponsor_name">Technology Business Incubator</div>
                            </div>
                        </Zoom>
                        <div style={{ margin: "20px" }}>
                            <Zoom>
                                <div className="sponsor_head">ASSOCIATE PARTNER</div>
                                <div style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div className="sponsor_image"><img className="sponsor_image" className="sponsor_image" src={require("../Assets/Sponsors/TS.webp")} /></div>
                                        <div className="sponsor_name">Turbostart</div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div className="sponsor_image"><img className="sponsor_image" src={require("../Assets/Sponsors/BB.webp")} /></div>
                                        <div className="sponsor_name">Bada Business</div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div className="sponsor_image"><img className="sponsor_image" src={require("../Assets/Sponsors/ACT.webp")} /></div>
                                        <div className="sponsor_name">ACT Fibernet</div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div className="sponsor_image"><img className="sponsor_image" src={require("../Assets/Sponsors/AS.webp")} /></div>
                                        <div className="sponsor_name">ASUS</div>
                                    </div>
                                </div>
                            </Zoom>
                        </div>
                    </div>
                    <Zoom>
                        {
                            Sponsor.map((Mapping) => {
                                return (
                                    <div style={{ margin: "30px", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                        <div className="sponsor_head">{Mapping.partner}</div>
                                        <div className="sponsor_image"><img className="sponsor_image" src={require("../Assets/Sponsors/" + Mapping.photo)} /></div>
                                        <div className="sponsor_name">{Mapping.name}</div>
                                    </div>
                                )
                            })
                        }
                    </Zoom>
                </div>
                <div style={{ display: "none", justifyContent: "center", flexWrap: "wrap" }}>
                    {Guests.map((Mapping) => {
                        return (
                            <Speakers
                                key={Mapping.name}
                                pic={require("../Assets/Guests/AA.webp")}
                                name={Mapping.name} />
                        )
                    })}
                    <button className="change_btn">
                        <i className="fa fa-arrow-right"></i>
                    </button>
                </div>

                <div style={{ display: "none", backgroundColor: "var(--color-3)" }}>
                    <div style={{ width: "50%" }}>
                        <img src={require("../Assets/logo.webp")} className="logo_fly" />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                        <div className="guest_heading"> Our Esteemed Guests </div>
                        <div className="sub_heading"> The best from the <br /> Entrepreneurship World </div>
                        <div style={{ display: "flex", marginTop: "50px" }}>
                            <div>
                                {this.state.index !== 0 ?
                                    <button onClick={reverse} className="change_btn">
                                        <i className="fa fa-arrow-left"></i>
                                    </button>
                                    : null}
                            </div>
                            <div style={{ display: "flex" }}>
                                {
                                    Guests.map((Mapping) => {
                                        return (
                                            <GuestPersons
                                                key={Mapping.name}
                                                name={Mapping.name}
                                                comments={Mapping.comments}
                                                post={Mapping.post}
                                                pic={require("../Assets/Guests" + Mapping.pic)} />
                                        )
                                    })
                                }
                                <div>
                                    {this.state.index !== (this.state.length - 1) ?
                                        <button onClick={forward} className="change_btn">
                                            <i className="fa fa-arrow-right"></i>
                                        </button>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Launchpad;